import React, { Suspense, lazy } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import GuestRoute from "./GuestRoute";
import CustomerProtectedRoute from "./CustomerProtectedRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";

import AddBanner from "Admin/Admincomponents/MainView/Banner/addBanner";
import ViewBanner from "Admin/Admincomponents/MainView/Banner/viewBanner";
import ViewOrder from "Pages/Profile/ViewOrder";
import ShopByCategory from "Pages/landingpage/mobileView/ShopByCategory";
import AllSubCategories from "Pages/AllSubCategories/AllSubCategories";
import TermsConditions from "Pages/TermsConditions";
import PrivacyPolicy from "Pages/PrivacyPolicy";
import NotFound from "Pages/ErrorPages/NotFound";

const Login = lazy(() => import("../Pages/Onboarding/Login"));
const Layout = lazy(() => import("../Pages/landingpage/Layout"));
const Categories = lazy(() => import("./Categories"));
const SubCategories = lazy(() =>
  import("../Pages/SubCategories/SubCategories")
);
const MainCategories = lazy(() =>
  import("../Pages/MainCategories/MainCategories")
);
const ShopByCategories = lazy(() =>
  import("../Pages/ShopByCategories/ShopByCategories")
);
const ShowChildOrProducts = lazy(() =>
  import("../Pages/ShowChildOrProducts.js")
);
const Signup = lazy(() => import("../Pages/Onboarding/Signup"));
const ForgotPassword = lazy(() => import("../Pages/Onboarding/ForgotPassword"));
const BlogList = lazy(() => import("../Pages/BlogList"));
const About = lazy(() => import("../Pages/About"));
const Careers = lazy(() => import("../Pages/Careers"));
const Faqs = lazy(() => import("../Pages/Faqs"));
const CustomerService = lazy(() => import("../Pages/CustomerService"));
const Cancellation = lazy(() => import("../Pages/Cancellation"));
const AdminLogin = lazy(() => import("../Admin/AdminLogin"));
const ContactUs = lazy(() => import("../Pages/ContactUs"));
const Product = lazy(() => import("../Pages/Product"));
const BlogsDetail = lazy(() => import("../Pages/BlogsDetail"));
const ProductSearch = lazy(() => import("../Pages/search/SearchPage"));
const AdminDashboard = lazy(() => import("../Admin/AdminDashboard"));
const MyProfile = lazy(() => import("../Pages/Profile/MyProfile"));
const ManagePassword = lazy(() => import("../Pages/Profile/ManagePassword"));
const Addresses = lazy(() => import("../Pages/Profile/Addresses"));
const MyOrders = lazy(() => import("../Pages/Profile/MyOrders"));
const Checkout = lazy(() => import("../Pages/Checkout/Checkout"));
const Cart = lazy(() => import("../Pages/Checkout/Cart"));
const OrderSuccess = lazy(() => import("../Pages/OrderResponse/success"));
const OrderFailed = lazy(() => import("../Pages/OrderResponse/fail"));
const PaymentStatus = lazy(() => import("../Pages/PaymentStatus"));
// const Dashboard = lazy(() => import("./MainView/Dashboard/Dashboard"));
const Wishlist = lazy(() => import("../Pages/Profile/Wishlist"));
const Dashboard = lazy(() =>
  import("../Admin/Admincomponents/MainView/Dashboard/Dashboard")
);
const AddOrEditCategories = lazy(() =>
  import("../Admin/Admincomponents/MainView/Categories/AddOrEditCategories")
);
const Viewcategories = lazy(() =>
  import("../Admin/Admincomponents/MainView/Categories/Viewcategories")
);
const AddOrEditProducts = lazy(() =>
  import("../Admin/Admincomponents/MainView/Products/AddOrEditProducts")
);
const Viewproducts = lazy(() =>
  import("../Admin/Admincomponents/MainView/Products/Viewproducts")
);

const Addtags = lazy(() =>
  import("../Admin/Admincomponents/MainView/Tags/Addtags")
);
const ViewBlogs = lazy(() =>
  import("../Admin/Admincomponents/MainView/Blogs/ViewBlogs")
);
const AddOrEditBlogs = lazy(() =>
  import("../Admin/Admincomponents/MainView/Blogs/AddOrEditBlogs")
);

const viewtags = lazy(() =>
  import("../Admin/Admincomponents/MainView/Tags/Viewtags")
);
const AddOrEditCoupons = lazy(() =>
  import("../Admin/Admincomponents/MainView/Coupons/AddOrEditCoupons")
);
const Viewcoupons = lazy(() =>
  import("../Admin/Admincomponents/MainView/Coupons/Viewcoupons")
);
const Ordermanagement = lazy(() =>
  import("../Admin/Admincomponents/MainView/Ordermanagement/Ordermanagement")
);
const OrderViewmanagement = lazy(() =>
  import(
    "../Admin/Admincomponents/MainView/Ordermanagement/OrderViewmanagement"
  )
);
const Viewusers = lazy(() =>
  import("../Admin/Admincomponents/MainView/Users/Viewusers")
);
const Contactusadmin = lazy(() =>
  import("../Admin/Admincomponents/MainView/Contact/contactus")
);
const ViewOneUser = lazy(() =>
  import("Admin/Admincomponents/MainView/Users/viewOneUser")
);

const ViewStock = lazy(() =>
  import("../Admin/Admincomponents/MainView/StockManagement/ViewStock")
);

const Logout = lazy(() => import("../Admin/AdminLogin"));

export default function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div className="loader"></div>}>
        <Switch>
          {/* Admin routes */}

          <AdminProtectedRoute
            exact
            path="/admin-dashboard"
            component={AdminDashboard}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/dashboard"
            component={Dashboard}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-categories/add-categories"
            component={AddOrEditCategories}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-categories/view-categories"
            component={Viewcategories}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-products/add-products"
            component={AddOrEditProducts}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-products/view-products"
            component={Viewproducts}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/edit-products/:id"
            component={AddOrEditProducts}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-tags/add-tags"
            component={Addtags}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-tags/view-tags"
            component={viewtags}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-coupons/add-coupons"
            component={AddOrEditCoupons}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-coupons/view-coupons"
            component={Viewcoupons}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/edit-coupons/:id"
            component={AddOrEditCoupons}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/order-management"
            component={Ordermanagement}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/order-management/view"
            component={OrderViewmanagement}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/view-users"
            component={Viewusers}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/view-contact-us"
            component={Contactusadmin}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/view-user/:id"
            component={ViewOneUser}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-blogs/add-blogs"
            component={AddOrEditBlogs}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-blogs/edit-blogs/:id"
            component={AddOrEditBlogs}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-blogs/view-blogs"
            component={ViewBlogs}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-banner/add-banner"
            component={AddBanner}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/admin-banner/view-banner"
            component={ViewBanner}
          />

          <AdminProtectedRoute
            exact
            path="/admin-dashboard/stock-management/view-stock"
            component={ViewStock}
          />

          {/* End of Admin routes */}

          {/* <GuestRoute exact path="/login" component={Login} />
					<GuestRoute exact path="/signup" component={Signup} /> */}
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute exact path="/contact-us" component={ContactUs} />
          <GuestRoute exact path="/blog" component={BlogList} />
          <GuestRoute exact path="/about-us" component={About} />
          <GuestRoute exact path="/careers" component={Careers} />
          <GuestRoute exact path="/faqs" component={Faqs} />
          {/* <GuestRoute exact path="/customerService" component={CustomerService} /> */}
          <GuestRoute
            exact
            path="/cancellation-return"
            component={Cancellation}
          />
          <GuestRoute
            exact
            path="/terms-conditions"
            component={TermsConditions}
          />
          <GuestRoute exact path="/privacy-policy" component={PrivacyPolicy} />
          <GuestRoute exact path="/admin-login" component={AdminLogin} />
         
          <GuestRoute exact path="/blog/:blogSlug" component={BlogsDetail} />
          <GuestRoute exact path="/" component={Layout} />

          {/* <GuestRoute
						exact
						path="/categories"
						component={Categories}
					/> */}
          {/* <GuestRoute
            exact
            path="/machineries/:categorySlug"
            component={SubCategories}
          /> */}
          {/* <GuestRoute exact path="/machineries" component={MainCategories} /> */}
          {/* <GuestRoute exact path="/spares" component={AllSubCategories} /> */}
          <GuestRoute exact path="/spares" component={MainCategories} />
          {/* <GuestRoute
            exact
            path="/spares/:categoryName"
            component={ShopByCategories}
          /> */}
<GuestRoute
            exact
            path="/spares/:categorySlug"
            component={SubCategories}
          />
<GuestRoute
            exact
            path="/spares/:categorySlug/:categoryName"
            component={ShowChildOrProducts}
          />
<GuestRoute
            exact
            path="/spares/:parentCategory/:categorySlug/:categoryName"
            component={ShopByCategories}
          />
          {/* <GuestRoute
						exact
						path="/search/:name/category/:category"
						component={ProductSearch}
					/> */}

          <GuestRoute exact path="/search" component={ProductSearch} />

          <CustomerProtectedRoute
            exact
            path="/account/profile"
            component={MyProfile}
          />

          <CustomerProtectedRoute
            exact
            path="/account/manage-password"
            component={ManagePassword}
          />

          <CustomerProtectedRoute
            exact
            path="/account/addresses"
            component={Addresses}
          />

          <CustomerProtectedRoute
            exact
            path="/account/my-orders/order-details/view"
            component={ViewOrder}
          />

          <CustomerProtectedRoute
            exact
            path="/account/my-orders"
            component={MyOrders}
          />


          <CustomerProtectedRoute exact path="/checkout" component={Checkout} />

          <CustomerProtectedRoute exact path="/cart" component={Cart} />

          <CustomerProtectedRoute
            exact
            path="/payment-successful"
            component={OrderSuccess}
          />

          <CustomerProtectedRoute
            exact
            path="/payment-failed"
            component={OrderFailed}
          />

          <CustomerProtectedRoute
            exact
            path="/account/wishlist"
            component={Wishlist}
          />
          {/* Admin generated paymnet link - payment status */}
          <GuestRoute
            exact
            path="/:category/:subCategory/:productSlug"
            component={Product}
          />
          <GuestRoute exact path="/payment-status" component={PaymentStatus} />
          <GuestRoute exact path="/logout" component={Logout} />

          {/* <GuestRoute path="*" component={NotFound} /> */}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
