import { useState } from "react";
import {Dropdown }from "react-bootstrap";
import SideBar from "../../../AdminDashboard";
import styles from "../../../Assets/Styling/banner.module.css";
import "../../../Assets/Styling/banner.css";
import { toast } from "react-toastify";
import API_Services from "../../../../utils/API_Services";
import UrlConstants from "../../../../utils/UrlConstants";

const AddBanner = () => {

    const [bannerImage, setBannerImage] = useState()
    const [previewImage, setPreviewImage] = useState()
    const [bannerNumber, setBannerNumber] = useState("Select banner Number")
    const [url, setUrl] = useState("")
    const [formError, setFormError] = useState()

    function handleSubmit(){
        if(!bannerImage || bannerNumber==="Select" || !url){
            alert("enter data")
            setFormError("* All fields must be filled")
        } else {
            setFormError("")
            alert("done")
            console.log({img:bannerImage, id: bannerNumber, url: url});
        }
    }

    function imageUpload(event){
        setPreviewImage(window.URL.createObjectURL(new Blob([event.target.files[0]], {type: "image/png"})))
        setBannerImage(event.target.files[0])
    }

    function addBanner() {

        console.log("ADD BANNER");

        if(
            bannerNumber === "" ||
            bannerNumber === "Select banner Number" ||
            bannerImage === "" ||
            bannerImage === undefined ||
            bannerImage === []
        ) {

            toast.error("Please fill all the details")

        }

        else {


            console.log("THE UPLOADED IMAGE::", bannerImage);

            var formdata = new FormData();
            
            formdata.append("url", (url === "") ? "#" : url);
            formdata.append("banner_position", bannerNumber);
            formdata.append("images", bannerImage);

            API_Services.httpPOSTWithToken(
                
                UrlConstants.URLS.addOrEditBanner,

                formdata,

                (err, response) => {

                    if(err) {

                        toast.error("Something went wrong!");

                    }   

                    else if(response && response.data) {

                        if(response.data.status) {

                            toast.success("Banner image uploaded");

                        }

                    } else {

                        toast.error("Something went wrong");

                    }

                }
            )

        }

    }

    return ( 
        <div>
            <div className={styles.addBannerWrapper}>
                <>
                    <SideBar />
                </>
                <div className={styles.addBannerBodyWrapper}>
                    <div className={styles.addBannerBodySubWrapper}>
                        <div className={styles.addBannerInputWrapper}>
                            <input type="file" onChange={(event) => imageUpload(event)} />
                            <div className={styles.addBannerInputSubWrapper}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="danger" id="dropdown-basic" className="addbannerBtn">
                                        {bannerNumber}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setBannerNumber(1)}>1</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(2)}>2</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(3)}>3</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(4)}>4</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(5)}>5</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(6)}>6</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(7)}>7</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(8)}>8</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setBannerNumber(9)}>9</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <button onClick={handleSubmit} className={styles.addBannerSubmit}>Upload</button> */}
                            </div>
                            <div className={styles.addBannerUrlWrapper}>
                                <input placeholder="Link URL *" className={styles.addBannerUrl} value={url} onChange={(event) => setUrl(event.target.value)}/>
                            </div>
                            <div>{formError}</div>

                            <div className="submit-btn-section" >
                                <button  
                                    className="submit-btn"
                                    onClick={ () => addBanner() }    
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div>
                            <img src={previewImage?previewImage:"https://ak.picdn.net/shutterstock/videos/16760683/thumb/1.jpg?ip=x480"} alt="preview" className={styles.addBannerImage}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default AddBanner;