import React from "react";
import * as Palette from "../Admin/color";
import "./Assets/Styling/AdminDashboard.css";
import Logo from "../Assets/Images/logo.png";

// Components
import Sidebar from "./Admincomponents/Sidebar/Sidebar";

const AdminDashboard = (props) => {
    const backgroundImage = "images/mountain.jpg";
    const sidebarHeader = {
        fullName: <img src={Logo} style={{ width: "90%" }}/>,
        shortName: "S4I",
    };
    const menuItems = [
        {
            name: "Dashboard",
            to: "/admin-dashboard/dashboard",
            icon: "/icons/home.svg",
            subMenuItems: [],
        },
        {
            name: "Categories",
            icon: "/icons/categories.svg",

            subMenuItems: [
                {
                    name: "View Categories",
                    to: "/admin-dashboard/admin-categories/view-categories",
                },
                {
                    name: "Add Category",
                    to: "/admin-dashboard/admin-categories/add-categories",
                },
            ],
        },

        {
            name: "Products",
            icon: "/icons/products.svg",

            subMenuItems: [
                {
                    name: "View Products",
                    to: "/admin-dashboard/admin-products/view-products",
                },
                {
                    name: "Add Product",
                    to: "/admin-dashboard/admin-products/add-products",
                },
            ],
        },

        {
            name: "Tags",
            icon: "/icons/tags.svg",

            subMenuItems: [
                { name: "View Tags", to: "/admin-dashboard/admin-tags/view-tags" },
                { name: "Add Tags", to: "/admin-dashboard/admin-tags/add-tags" },
            ],
        },

        {
            name: "Order Management",
            icon: "/icons/orders.svg",
            subMenuItems: [
                {
                    name: "View Orders",
                    to: "/admin-dashboard/order-management",
                },
            ],
        },

        {
            name: "Coupons",
            icon: "/icons/coupons.svg",

            subMenuItems: [
                { name: "View Coupons", to: "/admin-dashboard/admin-coupons/view-coupons" },
                { name: "Add Coupon", to: "/admin-dashboard/admin-coupons/add-coupons" },
            ],
        },
        {
            name: "Blogs",
            icon: "/icons/blog.svg",

            subMenuItems: [
                { name: "View Blogs", to: "/admin-dashboard/admin-blogs/view-blogs" },
                { name: "Add Blogs", to: "/admin-dashboard/admin-blogs/add-blogs" },
            ],
        },

        {
            name: "Banner Images",
            icon: "/icons/banner.svg", //idk the image, so im leaving some random img here

            subMenuItems: [
                { name: "View Banner Images", to: "/admin-dashboard/admin-banner/view-banner" },
                { name: "Add Banner Images", to: "/admin-dashboard/admin-banner/add-banner" },
            ],
        },

        {
            name: "Contact US",
            icon: "/icons/contact.svg",
            subMenuItems: [
                { name: "View Contact Us", to: "/admin-dashboard/view-contact-us" },
            ],
        },

        {
            name: "User Management",
            icon: "/icons/user.svg",
            subMenuItems: [
                { name: "View Users", to: "/admin-dashboard/view-users" },
            ],
        },

        {
            name: "Stock Management",
            // icon: "/icons/stock.svg",

            subMenuItems: [
                {
                    name: "View Stock",
                    to: "/admin-dashboard/stock-management/view-stock",
                },
            ],
        },

        

        {
            name: "Logout",
            icon: "/icons/logout.svg",
            subMenuItems: [],
        },
    ];

    const fonts = {
        header: "ZCOOL KuaiLe",
        menu: "Poppins",
    };

    return (
        <>
            <Sidebar
                backgroundImage={backgroundImage}
                sidebarHeader={sidebarHeader}
                menuItems={menuItems}
                fonts={fonts}
                colorPalette={Palette.brown}
                props={props}
            />
        </>
    );
};

export default AdminDashboard;
