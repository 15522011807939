import React, { Component } from 'react';

import API_Services from "../utils/API_Services";
import UrlConstants from "../utils/UrlConstants";

class Contact_Post extends Component {
    
    constructor(props) {
        super(props);
        const { steps  } = this.props;
        const { submit, name, email, subject, message, } = steps ;
    
        this.state =  { submit, name, email, subject, message }; 
      }
  
	createQuery = () => {
		
		let { submit, name, email, subject, message } = this.state;

        var reqBody = {
			name: name.value,
			email: email.value,
			subject: subject.value,
			message: message.value,
      source: "CHATBOT"
		};

        return API_Services.httpPOST(
          	UrlConstants.URLS.createQuery,
			reqBody,

			(err, response) => {

				if(err) {

					return(
						<div>
							Something went wrong! Please try again.
						</div>
					);

				}	

				else if(response.data) {

					if(response.data.status) {

						return(
							<div>
								Thank you! Your data was submitted successfully! We will contact you shortly.
							</div>
						)

					} else {

						return(
							<div>
								Something went wrong! Please try again.
							</div>
						)

					}

				} else {

					return(
						<div>
							Something went wrong! Please try again.
						</div>
					)

				}

			}
        );
	}

	componentDidMount = () => { this.createQuery(); }

	
    render() {
	    return (
			<>
				Thanks for Submitting your query. Our team will get in touch with you.
			</>
		);
    }
};  

export default Contact_Post;