import React from "react";
import { FaTimes } from "react-icons/fa";
import { productService } from "services";
import "../Assets/Styling/Categories.css";

class Categories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            mainCategories: [],
            subCategories: [],
            machineriesList: [],
            priceList: ["BELOW 5000", "5000-1000", "ABOVE 1000"],
            selectedCategoryId: "",
            mainSwitch: "products",
        };
    }

    componentDidMount = () => {
        this.getAllCategory();
    };

    getAllCategory = async () => {
        try {
            let categories = await productService.getAllCategory();

            if (categories?.data?.data?.length > 0) {
                this.setState({ mainCategories: categories?.data?.data });
                this.setState({
                    selectedCategoryId: this.props?.categoryId
                        ? this.props?.categoryId
                        : categories?.data?.data[0].id,
                });
                this.getProducts(categories?.data?.data[0].id);
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    AllCategories = () => {
        return (
            <div className="all-categories-section col-3 px-0 py-3 text-center">
                <div className="p-2">ALL CATEGORIES</div>
                <div className="categories-list">
                    {this.renderMainCategories()}
                </div>
            </div>
        );
    };

    renderMainCategories = () => {
        var catSwitcher = (id) => {
            if (id !== this.state.selectedCategoryId) {
                if (this.state.mainSwitch === "products") {
                    this.getProducts(id);
                }
                if (this.state.mainSwitch === "subCat") {
                    this.getAllSubCategories(id);
                }
            }
            this.setState({
                selectedCategoryId: id,
            });
        };

        return this.state.mainCategories.map((category) => {
            return (
                <div
                    className={
                        "category-name my-2 p-3 " +
                        (this.state.selectedCategoryId === category.id
                            ? "category-name-active"
                            : "")
                    }
                    onClick={() => catSwitcher(category.id)}
                >
                    {category.name}
                </div>
            );
        });
    };

    getAllSubCategories = async (value) => {
        try {
            let obj = {
                id: value,
            };

            let subCat = await productService.getAllSubCategories(obj);

            if (subCat?.data?.data) {
                this.setState({ subCategories: subCat?.data?.data });
            }
        } catch (err) {
            console.log(err);
            this.setState({ subCategories: [] });
        }
    };

    getProducts = async (value) => {
        try {
            let obj = {
                category: value,
            };

            let products = await productService.getProducts(obj);
            if (products?.data?.data.length > 0) {
                this.setState({ products: products?.data?.data });
            }
        } catch (err) {
            console.log(err);
            this.setState({ products: [] });
        }
    };

    handlePrice = (item) => {
        if (item.includes("BELOW")) {
            let data = item.split(" ");

            this.props?.history?.push({
                pathname: "/search",
                search: "?min=" + 0 + "&max=" + data[1],
            });
        } else if (item.includes("ABOVE")) {
            let data = item.split(" ");

            this.props?.history?.push({
                pathname: "/search",
                search: "?min=" + data[1] + "&max=" + 1000000,
            });
        } else {
            let data = item.split("-");

            this.props?.history?.push({
                pathname: "/search",
                search: "?min=" + data[0] + "&max=" + data[1],
            });
        }
        this.props?.toggleCategoriesModal();
    };

    handleSubCat = () => {
        this.props?.history?.push({
            pathname: "/search",
            search: "?category=" + this.state.selectedCategoryId,
        });
        this.props?.toggleCategoriesModal();
    };

    MainSection = () => {
        var mainSwitcher = (val) => {
            if (val === "products") {
                this.getProducts(this.state.selectedCategoryId);
            }
            if (val === "subCat") {
                this.getAllSubCategories(this.state.selectedCategoryId);
            }
            this.setState({
                mainSwitch: val,
            });
        };

        var renderMainSectionItems = () => {
            if (
                this.state.mainSwitch === "products" &&
                this.state.products.length > 0
            ) {
                return this.state.products.map((products) => {
                    return (
                        <div
                            className=" py-3 main-item"
                            onClick={() =>
                                (window.location.href =
                                    "/product/" + products.slug)
                            }
                        >
                            {products.product_name}
                        </div>
                    );
                });
            }

            if (
                this.state.mainSwitch === "subCat" &&
                this.state.subCategories
            ) {
                return this.state.subCategories.map((subCat) => {
                    return (
                        <div
                            className="py-3 main-item"
                            onClick={() => this.handleSubCat()}
                        >
                            {subCat.name}
                        </div>
                    );
                });
            }

            if (this.state.mainSwitch === "price") {
                return this.state.priceList.map((price) => {
                    return (
                        <div
                            className="py-3 main-item"
                            onClick={() => this.handlePrice(price)}
                        >
                            {price}
                        </div>
                    );
                });
            }
        };

        return (
            <div className="col-9 px-0">
                <div className="w-100 main-section-inner">
                    <div className="main-section-switcher">
                        <div className="switcher-inner w-100 d-flex flex-row align-items-center justify-content-between pr-3">
                            <div className="d-flex flex-row align-items-center justify-content-start">
                                <div
                                    className={
                                        "main-switch p-3 " +
                                        (this.state.mainSwitch === "products"
                                            ? "main-switch-active"
                                            : "")
                                    }
                                    onClick={() => mainSwitcher("products")}
                                >
                                    <div
                                        className={
                                            "m-2 " +
                                            (this.state.mainSwitch ===
                                            "products"
                                                ? "main-switch-border"
                                                : "")
                                        }
                                    >
                                        PRODUCTS
                                    </div>
                                </div>

                                <div
                                    className={
                                        "main-switch p-3 " +
                                        (this.state.mainSwitch === "subCat"
                                            ? "main-switch-active"
                                            : "")
                                    }
                                    onClick={() => mainSwitcher("subCat")}
                                >
                                    <div
                                        className={
                                            "m-2 " +
                                            (this.state.mainSwitch === "subCat"
                                                ? "main-switch-border"
                                                : "")
                                        }
                                    >
                                        {" "}
                                        SUB CATEGORY
                                    </div>
                                </div>

                                <div
                                    className={
                                        "main-switch p-3 " +
                                        (this.state.mainSwitch === "price"
                                            ? "main-switch-active"
                                            : "")
                                    }
                                    onClick={() => mainSwitcher("price")}
                                >
                                    <div
                                        className={
                                            "m-2 " +
                                            (this.state.mainSwitch === "price"
                                                ? "main-switch-border"
                                                : "")
                                        }
                                    >
                                        SHOP BY PRICE
                                    </div>
                                </div>
                            </div>

                            <div className="categories-close-section">
                                <FaTimes
                                    className="categories-close-btn"
                                    onClick={() =>
                                        this.props.toggleCategoriesModal()
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 h-100 main-items-section p-3">
                        {renderMainSectionItems()}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="categories-container d-flex">
                <div className="row m-auto inner">
                    {this.AllCategories()}
                    {this.MainSection()}
                </div>
            </div>
        );
    }
}

export default Categories;
