import React, { useState } from "react";
// import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import Categories from "./landingpage/leftSide/Categories";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Commitments from "../Components/Commitments";
import "../Assets/Styling/BlogGridView.css";

var UrlConstants = require("../utils/UrlConstants");
var _ = require("lodash");

const TermsConditions = () => {
  const [open, setOpen] = useState(false);

  const headingSection = () => {
    return (
      <div className="heading-section-all">
        <h1>Terms and Conditions</h1>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <Container fluid="true" className="bloggridview termsPage mb-5 content-section">
        <div style={{ display: "flex" }}>
          <div
            className="category-left-section"
            style={{ width: "290px", marginRight: "1rem" }}
          >
            <Categories />
          </div>
          <div className="main-right-section">
            {headingSection()}
            <div className="mainCareersLayout mt-4">
              <p className="headingCancel">General</p>
              <p>
                The issue of an order to Spares4India, the Seller, shall be
                deemed to constitute acceptance of the prices prevailing at the
                time of delivery by the purchaser and the following terms and
                conditions.
              </p>
              <p>
                No variation or modification of any of these terms and
                conditions shall be valid or effective save to the extent the
                Seller agrees in writing. These conditions shall be deemed to be
                accepted by the purchaser as overriding any alternative terms
                and conditions embodied in the purchaser's order.
              </p>
              <p className="headingCancel">Prices</p>
              <p>
                The Seller issues price lists and reserves the right to amend
                the prices without prior notice
              </p>
              <p className="headingCancel">Payment</p>
              <p>
                All prices are quoted on the understanding that payment for the
                goods and/or services supplied is made on invoice. If extended
                payment is agreed upon, then t is on the understanding that it
                may be withdrawn at any time without notice.
              </p>
              <p>
                If payment of an invoice or any part thereof is not made by the
                due date, the Seller shall be entitled
              </p>
              <p>
                To withhold further deliveries of goods without incurring any
                liability whatsoever to the purchaser, and/or
              </p>
              <p>
                To require payment in advance of delivery of undelivered goods
                or future orders.
              </p>
              <p className="headingCancel">Title and risk</p>
              <p>
                The risk in the goods shall pass to the purchaser upon delivery
                but title and beneficial ownership thereof shall remain with the
                Seller until it has received full payment of all outstanding
                debts, or until prior resale of the goods, in which case the
                Seller's beneficial entitlement shall attach to the proceeds of
                the resale pending full payment. Throughout the period of the
                Seller's beneficial ownership, the purchaser shall in his
                fiduciary capacity hold such goods and any proceeds or claim
                arising there from, for the Seller's benefit.
              </p>
              <p>
                If any of the Seller's goods should become constituents of or be
                procured or converted into other products, while subject to the
                Seller's beneficial ownership, the Seller shall have title to
                and beneficial ownership of such completed products.
              </p>
              <p className="headingCancel">Delivery</p>
              <p>
                The Seller will make every reasonable endeavour to deliver in
                accordance with delivery quoted, but does not undertake to
                deliver on a specific date or in the quantity specified by the
                purchaser unless prior arrangement and mutual agreement in
                writing. Such failure shall not entitle the purchaser to refuse
                acceptance of the goods or the Seller liable for any loss of any
                kind (which for the avoidance of doubt includes consequential
                loss or damage).
              </p>
              <p>
                Force majeure - If the Seller is prevented directly or
                indirectly from manufacturing or delivering the goods or any
                part thereof by reason of an act of God, war, terrorism, strike,
                lockout, trade dispute, fire, breakdown, interruption of
                transport or by any cause whatsoever outside its control, it
                shall be under no liability to the purchaser.
              </p>
              <p className="headingCancel">Liability</p>
              <p>
                The purchaser is responsible for ensuring that the goods are
                suitable for the purpose for which they are required or
                intended.
              </p>
              <p>
                Subject to the provisions of this clause, The Seller guarantees
                the goods against any defect, which can be proved to The
                Seller&#39;s satisfaction to have been caused by faulty
                materials or workmanship.
              </p>
              <p>
                Should such a defect appear, The Seller&#39;s liability will be
                limited solely to, at its option, repairing or replacing the
                defective goods free of charge, or refunding the price of those
                goods, provided that if The Seller opts to repair the goods, The
                Seller shall not be liable for the recovery cost of those goods
                and provided further that The Seller shall not be liable for
                such defect in any circumstances. If:
              </p>
              <ul>
                <li>
                  At the time of appearance of the defect, more than twelve
                  months has elapsed from the date of The Seller&#39;s invoice
                  for the goods, or
                </li>
                <li>
                  The goods have been subjected to unsuitable storage treatment
                  or handling prior to use, or to abnormal use, or use under
                  abnormal conditions, or to use contrary to The Seller&#39;s or
                  to a vehicles manufacturer&#39;s instructions or
                  recommendations, or to use in conjunction with parts not
                  approved by a vehicle manufacturer or The Seller, or to an
                  unauthorised reconditioning or repair, or to the use in any
                  hydraulic component of any fluid other than that approved by
                  The Seller, or
                </li>
                <br />
                <li>
                  The defect has been caused by, or arises from, fair wear and
                  tear, or
                </li>
                <br />
                <li>
                  The defect has been caused or contributed to by faulty
                  installation, servicing or repair of the goods by any person
                  other than a correctly qualified installer, or
                </li>
                <br />
                <li>
                  The buyer fails within thirty days after the assumed defect
                  has become apparent to notify that defect to The Seller in
                  writing quoting serial number, if any, and the date of
                  purchase.
                </li>
                <br />
              </ul>

              <p className="headingCancel">
                i. The Seller&#39;s aforesaid guarantee shall be subject to the
                following further provisos, namely:
              </p>
              <p>
                Any repair or examination of defective goods shall be carried
                out at The Seller&#39;s premises or other mutually acceptable
                location and The Buyer shall at its own expense and risk
                adequately pack the goods and deliver them to The Seller to the
                agreed location as the case may be.
              </p>
              <p>
                The decision of The Seller shall be final as to whether or not a
                defect is due to faulty workmanship or materials.
              </p>
              <p className="headingCancel">
                ii. The Seller shall in no circumstances be liable for any cost
                of stripping or reassembling any article into which any of The
                Seller&#39;s goods may have been fitted.
              </p>
              <p>
                If The Seller exercises its option to replace the defective
                goods, the replacement goods shall be supplied subject to these
                conditions of sale
              </p>
              <p>
                All other conditions or warranties (whether express or implied
                by common law statute or otherwise) as to qualify, fitness for
                purpose, description, correspondence with sample or (in the case
                of components supplied for competition or equivalent purposes)
                life in service are hereby excluded except insofar as such
                exclusion is prevented by law.
              </p>
              <p>
                Except as expressly provided in sub-clauses (a), (b), (c) and
                (d) of this clause and except where an absolute prohibition
                against exclusion and restriction of liability applies, The
                Seller shall in no circumstances be liable to The Purchaser in
                respect in any loss, damage or injury of any kind (which for the
                avoidance of doubt includes consequential loss, damage or
                injury) whether suffered by The Purchaser or by any other party
                and where such liability be in contract, tort, breach of
                statutory duty or otherwise howsoever (including for the
                avoidance of doubt liability for any defect in, failure, or
                unsuitability for any purposes of the goods or for any
                negligence whether in relation to the design or manufacture of
                the goods or at all) and all conditions, warranties or other
                terms whether express or implied by common law, statute or
                otherwise, inconsistent with the provisions of this term are
                hereby excluded. In particular The Purchaser of components
                supplied for competition or equivalent purposes shall be deemed
                to have recognised that such components are experimental in
                nature and that their use in competition or equivalent
                conditions may involve exceptional and unpredictable loadings
                and The Purchaser shall be solely responsible for ensuring the
                safe use of the goods in the application intended by The
                Purchaser.
              </p>
              <p>
                The Seller shall not be liable for any loss of any kind (which
                for the avoidance of doubt includes consequential loss or
                damage) arising from any representations, statements,
                warranties, recommendations, opinions or advice made or given
                before the making of this contract, whether the same be made
                negligently or otherwise. The expression &quot;consequential
                loss&quot; shall include loss of profits, loss of business
                revenue, loss of use orloss of goodwill whether of The Purchaser
                or of any other party.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="px-4 content-section">
        <Commitments />
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
