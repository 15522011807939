import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import Util from "../../utils/Util";
import { toast } from "react-toastify";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";

import "../../Assets/Styling/Login.css";
import spareslogo from "../../Assets/Images/spares_admin_panel_logo.png";

import LoginBanner from "../../Assets/Images/Onboarding/login-bg.png";
import GoogleIcon from "../../Assets/Images/Onboarding/google_icon.png";

import { API_CONSTANTS } from "../../Contants/APIConstants";
import API_Services from "../../utils/API_Services";
import ComponentLoader from "Components/ComponentLoader";
import { Spinner } from "react-bootstrap";

import { AiOutlineEye, AiFillEye } from "react-icons/ai";

var UrlConstants = require("../../utils/UrlConstants");
var Globals = require("../../utils/Globals");

var Login = (props) => {
  useEffect(() => {
    // console.log("login");
  }, []);
  // ? STATE VARIABLES
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [formErrorText, setFormErrorText] = useState("");

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [passwordToggle, setPasswordToggle] = useState(false);

  // ? API METHODS

  // ? CUSTOMER LOGIN - SITE METHOD
  var customerLogin = () => {
    setFormErrorText("");

    // ? REGULAR EXPRESSEIONS
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email === "" || password === "") {
      setFormErrorText("* Email and Password cannot be empty!");
    } else if (!emailRegex.test(email.toLowerCase())) {
      setFormErrorText("* Please enter a valid email address");
    } else {
      setBtnDisabled(true);

      var reqBody = {
        email: email,
        password: password,
      };

      // ? AXIOS POST CALL
      axios
        .post(UrlConstants.URLS.customerLogin, reqBody)
        .then((res) => {
          if (res.data) {
            if (!res.data.status) {
              setFormErrorText(res.data.message);
              setBtnDisabled(false);
              return;
            }

            var coreData = {
              userCoreProperties: {},
            };

            coreData.userCoreProperties.userID = res.data.userID;
            coreData.userCoreProperties.accessToken = res.data.token;
            coreData.userCoreProperties.userName = res.data.username;
            coreData.userCoreProperties.userEmail = res.data.email;
            coreData.userCoreProperties.userRole = res.data.user_role;
            coreData.userCoreProperties.name = res.data.name;
            coreData.userCoreProperties.su = res.data.su;

            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.CORE_DATA,
              JSON.stringify(coreData)
            );

            Util.setCookie("_lte", Date.now());

            toast.success("Login Success");

            // Save the newly generated userCoreProperties
            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES,
              JSON.stringify(coreData.userCoreProperties)
            );

            setBtnDisabled(false);

            window.location = "/";
          } else {
            setFormErrorText("Something went wrong!");
          }
        })
        .catch((err) => {
          setBtnDisabled(false);

          if (err && err.response && err.response.data) {
            setFormErrorText(err.response.data);
          } else {
            setFormErrorText("Something went wrong!");
            console.log("err...", err);
          }
        });
    }
  };
  // * END OF API METHODS

  // ? SOCIAL LOGIN METHODS

  // ? GOOGLE LOGIN
  var googleSuccessResponse = (response) => {
    setBtnDisabled(true);

    console.log("THE RESPONSE MFROM GOOOGLE BTN:", response);

    var reqBody = {
      tokenId: response.tokenId,
    };

    API_Services.httpPOST(UrlConstants.URLS.googleLogin, reqBody, (err, response) => {
    	if (err) {

    		setBtnDisabled(false);

    		if (err.response && err.response.data) {
    			toast.error(err.response.data.message);
    		} else {
    			toast.error("Google login error - Something went wrong!");
    			console.log("err...", err);
    		}
    	} else if (response.data) {
    		if (response.data.status) {

    			let data = response.data;

    			var coreData = {
    				userCoreProperties: {},
    			};

    			coreData.userCoreProperties.userID = data.userID;
    			coreData.userCoreProperties.accessToken =
    				data.token;
    			coreData.userCoreProperties.userName =
    				data.username;
    			coreData.userCoreProperties.userEmail = data.email;
    			coreData.userCoreProperties.userRole = data.user_role;
    			coreData.userCoreProperties.name = data.name;
    			coreData.userCoreProperties.su = data.su;

    			console.log("CORE DATA:: ", coreData);

    			localStorage.setItem(
    				Globals.ASYNC_STORAGE_KEYS.CORE_DATA,
    				JSON.stringify(coreData)
    			);

    			Util.setCookie("_lte", Date.now());

    			toast.success("Login Success");

    			// Save the newly generated userCoreProperties
    			localStorage.setItem(
    				Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES,
    				JSON.stringify(coreData.userCoreProperties)
    			);

    			setBtnDisabled(false);

    			window.location = "/";
    		} else {
    			setFormErrorText(response.data.message);

    			setBtnDisabled(false);

    			return;
    		}
    	} else {
    		toast.error("Google login error - Something went wrong!");

    		setBtnDisabled(false);
    	}
    });
  };

  let goolge = () => {

    window.open(UrlConstants.URLS.googleAuth, '_self');
  };

  var googleFailureResponse = (response) => {
    console.log("THE GOOGLE FAIL RESPONSE: ", response);

    if (response?.error === "popup_closed_by_user") {
      // TODO: DO NOTHING
    } else {
      setFormErrorText("Error Logging in with Google, please try again!");
    }

    setBtnDisabled(false);
  };
  //  * END OF GOOGLE LOGIN

  // ? FACEBOOK LOGIN
  var responseFacebook = (response) => {
    setBtnDisabled(true);

    console.log(response);

    if (response.status !== "unknown") {
      var reqBody = {
        accessToken: response.accessToken,
        userID: response.userID,
      };

      API_Services.httpPOST(
        UrlConstants.URLS.facebookLogin,
        reqBody,
        (err, response) => {
          if (err) {
            if (err.response && err.response.data) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Facebook login error - Something went wrong!");
              console.log("err...", err);
            }

            setBtnDisabled(false);
          } else if (response.data) {
            if (response.data.status) {
              let data = response.data;

              var coreData = {
                userCoreProperties: {},
              };

              coreData.userCoreProperties.userID = data.userID;
              coreData.userCoreProperties.accessToken = data.token;
              coreData.userCoreProperties.userName = data.username;
              coreData.userCoreProperties.userEmail = data.email;
              coreData.userCoreProperties.userRole = data.user_role;
              coreData.userCoreProperties.name = data.name;
              coreData.userCoreProperties.su = data.su;

              localStorage.setItem(
                Globals.ASYNC_STORAGE_KEYS.CORE_DATA,
                JSON.stringify(coreData)
              );

              Util.setCookie("_lte", Date.now());

              toast.success("Login Success");

              // Save the newly generated userCoreProperties
              localStorage.setItem(
                Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES,
                JSON.stringify(coreData.userCoreProperties)
              );

              setBtnDisabled(false);

              window.location = "/";
            } else {
              setFormErrorText(response.data.message);
              setBtnDisabled(false);
              return;
            }
          } else {
            toast.error("Facebook login error - Something went wrong!");
            setBtnDisabled(false);
          }
        }
      );
    } else {
      setBtnDisabled(false);
    }
  };

  // * END OF FACEBOOK LOGIN

  // * END OF SOCIAL LOGIN METHODS

  // ? NON-RENDERING METHODS

  // ? Method to save the JWT token in the browser's local storage
  var saveJwtToken = (token) => {
    localStorage.setItem("sparesToken", token);
  };
  // * End of saveJwtToken()

  //  * END OF NON-RENDERING METHODS

  // ? RENDERING METHODS

  // ? Login pop-up image section
  var LoginImageSection = () => {
    return (
      <div className="col-12 col-md-6 login-image-section p-0 d-none d-lg-block">
        <img src={LoginBanner} alt="OnboardingBanner" />
      </div>
    );
  };
  // * End of LoginImageSection()

  /**
   * ? Method to render the Loader when user login inorder to prevent multiple clicks
   */
  var renderLoader = () => {
    return (
      <div className="col-12 col-md-6 login-form-container login-loader-section">
        <ComponentLoader />
      </div>
    );
  };

  // ? Rendering the login form
  var LoginForm = () => {
    /**
     * ? Method to render either the text or the Loader in the button depending on the state
     */
    var btnText = () => {
      if (btnDisabled) {
        return <Spinner animation="border" />;
      } else {
        return "Sign in";
      }
    };
    // * End of btnText()

    return (
      <>
        {/* { renderLoader() } */}
        <div className="col-12 offset-md-6 col-md-6 login-form-container">
          <div className="d-flex flex-row align-items-center justify-content-end w-100 pt-3 ">
            <FaTimes
              className="login-close-btn"
              onClick={() => props.toggleLoginModal()}
            />
          </div>

          <div className="mb-4 text-center d-xl-none d-lg-none d-md-none">
            <img src={spareslogo} className="img-fluid" style={{width: '200px'}} />
          </div>

          <div className="login-title-section p-0 d-xl-none d-lg-none d-md-none text-center">
            <p className="p-0">Login</p>
          </div>

          <div className="loginDetailsWrapper row px-5 py-3">
            <div className="col-12 p-0 social-icon-section">
              <FacebookLogin
                appId="274413811339032"
                callback={responseFacebook}
                cssClass="facebook-login-btn"
                icon="fa-facebook"
                textButton=" &nbsp;Facebook"
              />
            </div>

            <div className=" col-12 p-0 social-icon-section">
              <GoogleLogin
               
                clientId="33818058037-6mhfnjtlon34chk6fkrj9b3rc1tap9k3.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={googleSuccessResponse}
                onFailure={googleFailureResponse}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <button
									className="social-login-btn w-100"
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
								>
									<img src={GoogleIcon} /> &nbsp;Google
								</button>
                )}
              />
              {/* <div className="w-100">
                <button className="social-login-btn w-100" onClick={goolge}>
                  <img src={GoogleIcon} /> &nbsp;Google
                </button>
              </div> */}
            </div>

            <div className="col-12 or-text-section p-0">
              <div className="or-div">
                <span className="or-text">or</span>
              </div>
            </div>

            {/* <p className="col-12 p-0 input-label">Email</p> */}
            <input
              type="text"
              className="email-input col-12"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            {/* <p className="col-12 p-0 input-label">Password</p> */}
            <div className="password-input-container col-12 p-0">
              <input
                type={passwordToggle ? "text" : "password"}
                className="password-input"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div
                className="toggle-password-icon"
                onClick={() => setPasswordToggle(!passwordToggle)}
              >
                {!passwordToggle ? (
                  <AiOutlineEye size="25" />
                ) : (
                  <AiFillEye size="25" />
                )}
              </div>
            </div>

            <div className="col-12 p-0 mb-3">
              <Link className="forgot-pass-btn w-100" to="/forgot-password">
                Forgot Password?
              </Link>
            </div>

            <div className="error-msg-section">{formErrorText} &nbsp;</div>

            <div className="col-12 p-0 mb-2">
              <button
                className="login-btn w-100"
                onClick={() => customerLogin()}
                disabled={btnDisabled}
              >
                {btnText()}
              </button>
            </div>

            <div className="col-12 text-center create-acc-text">
              <p className="m-0">
                Don't have an account?&nbsp;
                <u
                  onClick={() => props.openSignupFromLogin()}
                  style={{ cursor: "pointer" }}
                >
                  Create Account
                </u>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  // * End of LoginForm()

  // * END OF RENDRING-METHODS

  return (
    <div className="login-container d-flex">
      <div className="row m-auto inner">
        {/* {LoginImageSection()} */}

        {LoginForm()}
      </div>
    </div>
  );
};

export default Login;
