import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import Util from "../../utils/Util";
import API_Services from "../../utils/API_Services";
import spareslogo from "../../Assets/Images/spares_admin_panel_logo.png";

import "../../Assets/Styling/Signup.css";

import OnboardingBanner from "../../Assets/Images/Onboarding/onboarding_banner_new.jpg";
import GoogleIcon from "../../Assets/Images/Onboarding/google_icon.png";

import { Spinner } from "react-bootstrap";

import { API_CONSTANTS } from "../../Contants/APIConstants";

import { AiOutlineEye, AiFillEye } from "react-icons/ai";

var UrlConstants = require("../../utils/UrlConstants");
var Globals = require("../../utils/Globals");

var Signup = (props) => {
  // ? STATE VARIABLES
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [gstCheck, setGstCheck] = useState(false);
  const [gst, setGst] = useState("");
  const [company, setCompany] = useState("");

  const [formErrorText, setFormErrorText] = useState("");

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [passwordToggle, setPasswordToggle] = useState(false);

  // ? Customer signup
  var customerSignup = () => {
    // ? REGULAR EXPRESSEIONS
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneNumberRegex = /^[6-9]\d{9}$/;
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (name === "" || email === "" || password === "" || phoneNumber === "") {
      setFormErrorText("* Please fill all the details");
    } else if (!emailRegex.test(email.toLowerCase())) {
      setFormErrorText("* Please enter a valid email address");
    } else if (!phoneNumberRegex.test(phoneNumber)) {
      setFormErrorText("* Please enter a valid phone number");
    } else if (!passwordRegex.test(password)) {
      setFormErrorText(
        "* Password must contain atleast one uppercase character, one lowercase character, one number, one special character and must be atleast 8 characters long."
      );
    } else if (password.length < 8) {
      setFormErrorText("* Password should contain more than 8 characters");
    } else if (gstCheck && gst === "") {
      setFormErrorText("* GST no is mandatory");
    } else {
      setBtnDisabled(true);

      var reqBody = {
        name: name,
        email: email,
        phone_number: phoneNumber,
        password: password,
        signup_method: "SITE",
        gst: gstCheck ? gst : "",
        comapny: gstCheck ? company : "",
      };

      axios
        .post(UrlConstants.URLS.customerSignup, reqBody)
        .then((res) => {
          if (res.data) {
            if (!res.data.status) {
              setFormErrorText(res.data.message);
              setBtnDisabled(false);
              return;
            }

            var coreData = {
              userCoreProperties: {},
            };

            coreData.userCoreProperties.userID = res.data.userID;
            coreData.userCoreProperties.accessToken = res.data.token;
            coreData.userCoreProperties.userName = res.data.username;
            coreData.userCoreProperties.userEmail = res.data.email;
            coreData.userCoreProperties.userRole = res.data.user_role;
            coreData.userCoreProperties.name = res.data.name;
            coreData.userCoreProperties.su = res.data.su;

            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.CORE_DATA,
              JSON.stringify(coreData)
            );

            Util.setCookie("_lte", Date.now());

            toast.success("Signup Success");

            // Save the newly generated userCoreProperties
            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES,
              JSON.stringify(coreData.userCoreProperties)
            );

            setBtnDisabled(false);

            window.location = "/";
          } else {
            setFormErrorText("Something went wrong!");
            setBtnDisabled(false);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            setFormErrorText(err.response.data);
          } else {
            setFormErrorText("Something went wrong!");
            console.log("err...", err);
          }
          setBtnDisabled(false);
        });
    }
  };

  // * END OF API METHODS

  // ? SOCIAL LOGIN METHODS

  // ? GOOGLE LOGIN
  var googleSuccessResponse = (response) => {
    setBtnDisabled(true);

    console.log("THE RESPONSE MFROM GOOOGLE BTN:", response);

    var reqBody = {
      tokenId: response.tokenId,
    };

    API_Services.httpPOST(
      UrlConstants.URLS.googleLogin,
      reqBody,
      (err, response) => {
        if (err) {
          if (err.response && err.response.data) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Google login error - Something went wrong!");
            console.log("err...", err);
          }

          setBtnDisabled(false);
        } else if (response.data) {
          if (response.data.status) {
            let data = response.data;

            var coreData = {
              userCoreProperties: {},
            };

            coreData.userCoreProperties.userID = data.userID;
            coreData.userCoreProperties.accessToken = data.token;
            coreData.userCoreProperties.userName = data.username;
            coreData.userCoreProperties.userEmail = data.email;
            coreData.userCoreProperties.userRole = data.user_role;
            coreData.userCoreProperties.name = data.name;
            coreData.userCoreProperties.su = data.su;

            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.CORE_DATA,
              JSON.stringify(coreData)
            );

            Util.setCookie("_lte", Date.now());

            toast.success("Signup Success");

            // Save the newly generated userCoreProperties
            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES,
              JSON.stringify(coreData.userCoreProperties)
            );

            setBtnDisabled(false);

            window.location = "/?nu=1";
          } else {
            setFormErrorText(response.data.message);
            setBtnDisabled(false);
            return;
          }
        } else {
          toast.error("Google login error - Something went wrong!");
          setBtnDisabled(false);
        }
      }
    );
  };
  let goolge = () => {
    window.open(UrlConstants.URLS.googleAuth, "_self");
  };
  var googleFailureResponse = (response) => {
    // toast.error("ERROR LOGGING IN WITH GOOGLE, PLEASE TRY AGAIN!");
    setFormErrorText("* Error logging in with Google, please try again!");
    setBtnDisabled(false);
  };
  //  * END OF GOOGLE LOGIN

  // ? FACEBOOK LOGIN
  var responseFacebook = (response) => {
    setBtnDisabled(true);

    console.log(response);

    var reqBody = {
      accessToken: response.accessToken,
      userID: response.userID,
    };

    API_Services.httpPOST(
      UrlConstants.URLS.facebookLogin,
      reqBody,
      (err, response) => {
        if (err) {
          if (err.response && err.response.data) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Facebook login error - Something went wrong!");
            console.log("err...", err);
          }

          setBtnDisabled(false);
        } else if (response.data) {
          if (response.data.status) {
            let data = response.data;

            var coreData = {
              userCoreProperties: {},
            };

            coreData.userCoreProperties.userID = data.userID;
            coreData.userCoreProperties.accessToken = data.token;
            coreData.userCoreProperties.userName = data.username;
            coreData.userCoreProperties.userEmail = data.email;
            coreData.userCoreProperties.userRole = data.user_role;
            coreData.userCoreProperties.name = data.name;
            coreData.userCoreProperties.su = data.s;

            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.CORE_DATA,
              JSON.stringify(coreData)
            );

            Util.setCookie("_lte", Date.now());

            toast.success("Login Success");

            // Save the newly generated userCoreProperties
            localStorage.setItem(
              Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES,
              JSON.stringify(coreData.userCoreProperties)
            );

            setBtnDisabled(false);

            window.location = "/?nu=1";
          } else {
            setFormErrorText(response.data.message);

            setBtnDisabled(false);

            return;
          }
        } else {
          toast.error("Facebook login error - Something went wrong!");
          setBtnDisabled(false);
        }
      }
    );
  };

  // * END OF FACEBOOK LOGIN

  // * END OF SOCIAL LOGIN METHODS

  // ? NON-RENDERING METHODS

  // ? Method to save the JWT token in the browser's local storage
  var saveJwtToken = (token) => {
    localStorage.setItem("sparesToken", token);
  };
  // * End of saveJwtToken()

  //  * END OF NON-RENDERING METHODS

  // RENDRING METHODS
  var imageSection = () => {
    return (
      <div className="signup-img-section d-none d-lg-block">
        <div className="signup-img-section-inner">
          <img src={OnboardingBanner} />
        </div>
      </div>
    );
  };

  var gstInputs = () => {
    if (gstCheck) {
      return (
        <>
          {/* <p className="input-label p-0">GST (Optional)</p> */}
          <input
            type="text"
            className="signup-input col-12"
            placeholder="GST"
            value={gst}
            onChange={(event) => setGst(event.target.value)}
          />

          {/* <p className="col-12 p-0 input-label">Company Name (Optional)</p> */}
          <input
            type="text"
            className="signup-input col-12"
            placeholder="Company Name (Optional)"
            value={company}
            onChange={(event) => setCompany(event.target.value)}
          />
        </>
      );
    }
  };

  var formSection = () => {
    /**
     * ? Method to render either the text or the Loader in the button depending on the state
     */
    var btnText = () => {
      if (btnDisabled) {
        return <Spinner animation="border" />;
      } else {
        return "Sign up";
      }
    };
    // * End of btnText()

    return (
      <div className="offset-md-6 cold-md-6 signup-form-section">
        {/* CLOSE BTN */}
        <div className="signup-close-section">
          <FaTimes
            className="signup-close-btn"
            onClick={() => props.toggleSignupModal()}
          />
        </div>

        <div className="signup-form-fields-section px-5 py-3">
          <div className="mb-4 d-xl-none d-lg-none d-md-none">
            <img
              src={spareslogo}
              className="img-fluid"
              style={{ width: "200px" }}
            />
          </div>

          <div className="signup-title-section p-0 d-xl-none d-lg-none d-md-none text-center">
            <p className="p-0">Register</p>
          </div>

          <div className="col-12 p-0 social-icon-section">
            <FacebookLogin
              appId="1495477094120502"
              callback={responseFacebook}
              cssClass="facebook-login-btn"
              icon="fa-facebook"
              textButton=" &nbsp;Facebook"
            />
          </div>

          <div className=" col-12 p-0 social-icon-section">
            <GoogleLogin
              clientId="33818058037-6mhfnjtlon34chk6fkrj9b3rc1tap9k3.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={googleSuccessResponse}
              onFailure={googleFailureResponse}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <button
                  className="social-login-btn w-100"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img src={GoogleIcon} /> &nbsp;Google
                </button>
              )}
            />
            {/* <div className="w-100">
              <button className="social-login-btn w-100" onClick={goolge}>
                <img src={GoogleIcon} /> &nbsp;Google
              </button>
            </div> */}
          </div>

          <div className="col-12 or-text-section">
            <div className="or-div">
              <span className="or-text">or</span>
            </div>
          </div>

          {/* <p className="input-label p-0">Name</p> */}
          <input
            type="text"
            className="signup-input col-12"
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          {/* <p className="input-label p-0">Email</p> */}
          <input
            type="text"
            className="signup-input col-12"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          {/* <p className="input-label p-0">Phone Number</p> */}
          <input
            type="text"
            className="signup-input col-12"
            placeholder="Mobile Number"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
          />

          {/* <p className="input-label p-0">Password</p> */}
          <div className="password-input-container col-12 p-0">
            <input
              type={passwordToggle ? "text" : "password"}
              className="password-input"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <div
              className="toggle-password-icon"
              onClick={() => setPasswordToggle(!passwordToggle)}
            >
              {!passwordToggle ? (
                <AiOutlineEye size="25" />
              ) : (
                <AiFillEye size="25" />
              )}
            </div>
          </div>

          {/* GST CHECKBOX */}
          <div className="checkbox-section p-0 mb-2">
            <input
              type="checkbox"
              value={gstCheck}
              onChange={() => setGstCheck(!gstCheck)}
            />{" "}
            Company
          </div>

          {gstInputs()}

          <div className="error-msg-section">{formErrorText} &nbsp;</div>

          <div className="col-12 p-0 mb-3 signup-btn-section">
            <button
              className="signup-btn w-100"
              onClick={() => customerSignup()}
              disabled={btnDisabled}
            >
              {btnText()}
            </button>
          </div>

          <div className="col-12 text-center">
            <p>
              Already have an account? &nbsp;
              <span
                className="login-link"
                onClick={() => props.openLoginFromSignup()}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  // END OF RENDERING METHODS

  return (
    <div className="signup-container d-flex">
      <div className="m-auto inner">
        <div className="signup-popup">
          {/* {imageSection()} */}

          {formSection()}
        </div>
      </div>
    </div>
  );
};

export default Signup;
