module.exports = {

	APP_NAME: "Spares 4 India",

	APP_DESCRIPTION: "The Spare Parts Expert",

	/* ASYNC STORAGE KEYS */
	ASYNC_STORAGE_KEYS: {
		USER_CORE_PROPERTIES: 'AS_APP_USER_PROPERTIES',
		CORE_DATA: 'CORE_DATA',
	},

	/* ENVIRONMENT */
	ENVIRONMENT: {

		ENV: process.env.NODE_ENV === 'development' ? 'DEV' : (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? 'PRODUCTION' : 'STAGING'),

		// ENV: "PRODUCTION",

		DEV: {
			FRONTEND_BASE_URL: 'http://localhost:3000/',
			BACKEND_BASE_URL: 'http://localhost:1337/',
			REACT_APP_GA: '',
			GOOGLE_CLIENT_ID: '',
			RAZORPAY_KEY: 'rzp_test_3QZs5J6T8sipIe',
			S3: "https://spares-assets-stg.s3.ap-south-1.amazonaws.com/",
			DELHIVERY_TRACK_URL: "https://www.delhivery.com/track/package/"
		},

		STAGING: {
			FRONTEND_BASE_URL: 'http://ec2-3-109-168-6.ap-south-1.compute.amazonaws.com/',
			BACKEND_BASE_URL: 'http://ec2-3-109-168-6.ap-south-1.compute.amazonaws.com:1337/',
			REACT_APP_GA: '',
			GOOGLE_CLIENT_ID: '',
			RAZORPAY_KEY: 'rzp_test_3QZs5J6T8sipIe',
			S3: "https://spares-assets-stg.s3.ap-south-1.amazonaws.com/",
			DELHIVERY_TRACK_URL: "https://www.delhivery.com/track/package/"
		},

		PRODUCTION: {
			FRONTEND_BASE_URL: 'https://spares4india.com/',
			BACKEND_BASE_URL: 'https://api.spares4india.com/',
			REACT_APP_GA: '',
			GOOGLE_CLIENT_ID: '',
			RAZORPAY_KEY: 'rzp_live_twI5pVO6uGe8F4',
			S3: "https://spares-assets.s3.ap-south-1.amazonaws.com/",
			DELHIVERY_TRACK_URL: "https://www.delhivery.com/track/package/"
		},
	},

	CURRENCY: "₹",

	BEST_SELLER_PRODUCTS_LIMIT_STOREFRONT: 12,
	HOT_PRODUCTS_LIMIT_STOREFRONT: 12,
};
