import { useEffect, useState } from "react";
import SideBar from "../../../AdminDashboard";
import styles from "../../../Assets/Styling/banner.module.css"
import API_Services from "../../../../utils/API_Services";
import UrlConstants from "../../../../utils/UrlConstants";
import { toast } from "react-toastify";

const ViewBanner = () => {

    const [images, setImages] = useState()

    // * Method to render the Banner images Table 
    var renderImageList = () => {

        API_Services.httpGETWithToken(

            UrlConstants.URLS.getAllBannerImage,

            (err, response) => {

                if(err) {

                    toast.error("Something went wrong!");

                }

                if(response && response.data) {

                    if(response.data.status) {

                        setImages(response.data.data);

                    }

                } else {

                    toast.error("Something went wrong!");

                }

            }

        )

    }
    // ? End of renderImageList()


    // * Method to call API for deleting the banner image
    function deleteBannerImage(id) {

        var requestObject = {

            id: id,
            
        };

        API_Services.httpPOSTWithToken(
            
            UrlConstants.URLS.deleteBannerImage,
            
            requestObject,

            (err, response) => {

                if(err) {

                    toast.error("Something went wrong!");
                    
                }
                
                if(response && response.data) {
                    
                    if(response.data.status === false) {
                        
                        toast.error("Something went wrong!");

                    } else if(response.data.status) {

                        toast.success("Banner image deleted");

                        renderImageList();

                    }

                }

            },
        )

    }
    // ? End of deleteBannerImage()

    
    useEffect(() => {

        renderImageList();

    }, [])


    return ( 
        <div>
            <div className={styles.viewBannerWrapper}>
                <>
                    <SideBar />
                </>
                <div className={styles.viewBannerBodyWrapper}>
                <table className={styles.viewBannerBorderStd}>
                    <tr className={styles.viewBannerBorderStd}>
                        <th className={styles.viewBannerPaddingOne}>BannerId</th>
                        <th className={styles.viewBannerPaddingOne}>Image</th>
                        <th className={styles.viewBannerPaddingOne}>URL</th>
                        <th className={styles.viewBannerPaddingOne}>Delete</th>
                    </tr>
                    {images?images.map(image => (
                        <tr className={styles.viewBannerBorderStd}>
                            <th className={styles.viewBannerPaddingOne}>{image.banner_position}</th>
                            <th className={styles.viewBannerPaddingOne}><img src={image.path} alt="preview" style={{ width: "100px" }}/></th>
                            <th className={styles.viewBannerPaddingOne}>{image.url}</th>
                            <th><button className={styles.viewBannerDelete} onClick={ () => deleteBannerImage(image.id) }>Delete</button></th>
                        </tr>
                    )):<tr />}
                </table>
                </div>
            </div>
        </div>
     );
}
 
export default ViewBanner;