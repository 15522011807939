import React, { useEffect, useState } from "react";
import { FaAngleDown, FaInstagram, FaFacebook } from "react-icons/fa";
import { useHistory } from "react-router";
import "../Assets/Styling/Home.css";
import "../Assets/Styling/Footer.css";
import { toast } from "react-toastify";
import CategoriesModal from "../Components/Categories";
import API_Services from "../../src/utils/API_Services";
import allCardsAccept from "../Assets/Images/img/all-cards-accept.png";
import footerlogo from "../Assets/Images/spares-logo.png";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import Conatct_Post from "../Pages/Conatct_Post";

var UrlConstants = require("../../src/utils/UrlConstants");

var Footer = (props) => {
  const [SubscriberEmail, setSubscriberEmail] = useState("");

  const [quickLinks, setQuickLinks] = useState(false);
  const [catShow, setCat] = useState(false);
  const [partsSelector, setPartsSelector] = useState("cat");
  const [allData, setAllData] = useState([]);

  const [catSelectors, setCatSelectors] = useState(["No Categories"]);
  const [machSelectors, setMachSelectors] = useState(["No Machines"]);
  const [popularSelectors, setPopularSelectors] = useState([
    "BELOW 5000",
    "5000-10000",
    "ABOVE 10000",
  ]);
  const [categoriesModal, setCategoriesModal] = useState(false);

  const router = useHistory();

  const [device, setDevice] = useState("Home");

  const theme = {
    background: "#f5f8fb",
    fontFamily: "OPEN SANS",
    headerBgColor: "#ff0000",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#eeeeee",
    botFontColor: "#000",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  const config = {
    width: "400px",
    height: "500px",
    floating: true,
  };

  const steps = [
    {
      id: "q-name",
      message: "What is your name?",
      trigger: "name",
    },
    {
      id: "name",
      user: true,
      validator: (value) => {
        if (value || value.length !== 0 || value !== "") {
          return true;
        } else {
          return "Name cannot be empty!";
        }
      },
      trigger: "q-email",
    },

    {
      id: "q-email",
      message: "What is your email?",
      trigger: "email",
    },
    {
      id: "email",
      user: true,
      validator: (value) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          return true;
        } else {
          return "Please enter a valid email ID.";
        }
      },
      trigger: "q-subject",
    },
    {
      id: "q-subject",
      message: "Please let us know what you are looking for",
      trigger: "subject",
    },
    {
      id: "subject",
      user: true,
      validator: (value) => {
        if (value || value.length !== 0 || value !== "") {
          return true;
        } else {
          return "Subject cannot be empty!";
        }
      },
      trigger: "q-message",
    },
    {
      id: "q-message",
      message: "Please elaborate your query",
      trigger: "message",
    },
    {
      id: "message",
      user: true,
      validator: (value) => {
        if (value || value.length !== 0 || value !== "") {
          return true;
        } else {
          return "This cannot be empty!";
        }
      },
      trigger: "q-submit",
    },
    {
      id: "q-submit",
      message: "Do you wish to submit?",
      trigger: "submit",
    },
    {
      id: "submit",
      options: [
        { value: "y", label: "Yes", trigger: "end-message" },
        { value: "n", label: "No", trigger: "no-submit" },
      ],
    },
    {
      id: "no-submit",
      message: "Your information was not submitted.",
      end: true,
    },
    {
      id: "end-message",
      component: <Conatct_Post />,
      asMessage: true,
      end: true,
    },
  ];

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setDevice("mobile");
    } else {
      setDevice("Home");
    }

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
    if (isTablet) {
      setDevice("tablet");
    }
  }, [device]);

  useEffect(() => {
    getAllCategoriesFunc();
  }, []);

  function getAllCategoriesFunc() {
    let postObj = {};

    API_Services.httpPOST(
      UrlConstants.URLS.getCategoryTree,
      postObj,
      (err, response) => {
        if (err) {
          if (err.response && err.response.data) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong!");
            console.log("err...", err);
          }
        } else if (response.data) {
          if (response.data.status) {
            setAllData(response.data.data);
            setAllCategoriesFun(response.data.data, "cat");
          } else {
            toast.error(response.data.message);
          }
        } else {
          toast.error("Something went wrong!");
        }
      }
    );
  }

  function compareDate(a, b) {
    return b.updatedAt - a.updatedAt;
  }

  function setAllCategoriesFun(data, type) {
    let arr = [];
    data.sort(compareDate).reverse();
    if (type == "cat") {
      data?.forEach((item, index) => {
        if (index < 20) {
          let obj = {
            id: item.id,
            name: item.name,
            slug: item.slug,
          };
          arr.push(obj);
        }
      });
      setCatSelectors(arr);
    }
    if (type == "mach") {
      data.forEach((item) => {
        item?.children.forEach((item, index) => {
          if (index < 20) {
            let obj = {
              id: item.id,
              name: item.name,
              slug: item.slug,
            };

            arr.push(obj);
          }
        });
      });
      setMachSelectors(arr);
    }
  }

  function handleSelectors(type) {
    setPartsSelector(type);
    setAllCategoriesFun(allData, type);
  }

  // ? RENDERING METHODS
  // ? The Spare Parts Expert
  var SparePartsExpert = () => {
    var selectorSwitcher = (value) => {
      setPartsSelector(value);
    };

    return (
      <div
        style={
          device === "mobile"
            ? { position: "relative", paddingTop: "4rem" }
            : { position: "relative", paddingTop: "20px" }
        }
      >
        <div className="footerFlotinText">
          <div
            className={
              device === "mobile"
                ? "spare-parts-bubble text-center py-2 px-5 "
                : "spare-parts-bubble text-center py-3 px-5"
            }
            style={
              device === "mobile"
                ? { fontSize: "1rem", marginTop: "2.75rem" }
                : {}
            }
          >
            THE SPARE PARTS EXPERT
          </div>
        </div>
        {device === "mobile" ? null : (
          <div className="spare-parts-expert-section py-5">
            <div className="parts-selector-section d-flex flex-row mx-auto mb-3 p-0 ">
              <div
                className={
                  "selector-btn " +
                  (partsSelector === "cat" ? "selector-btn-active" : "")
                }
                onClick={() => handleSelectors("cat")}
              >
                Shop by Machineries
              </div>
              <div
                className={
                  "selector-btn " +
                  (partsSelector === "mach" ? "selector-btn-active" : "")
                }
                onClick={() => handleSelectors("mach")}
              >
                Shop by Spares
              </div>
              <div
                className={
                  "selector-btn " +
                  (partsSelector === "popular" ? "selector-btn-active" : "")
                }
                onClick={() => handleSelectors("popular")}
              >
                Shop By Price
              </div>
            </div>

            <div
              className="row selectors-list-section px-5"
              //row-container selectors-list-section
            >
              {renderSelectorsList()}
            </div>
          </div>
        )}
      </div>
    );
  };
  // * End of SparePartsExpert()

  function handleCategory(id) {
    router.push({
      pathname: "/search",
      search: "?category=" + id,
    });
  }

  function handleSubCategory(id) {
    let filteredData = getFilteredData(id);

    if (filteredData) {
      handleCategory(filteredData?.id);
    }
  }

  function getFilteredData(id) {
    for (let i = 0; i < allData.length; i++) {
      for (let j = 0; j < allData[i]?.children?.length; j++) {
        if (allData[i]?.children[j].id == id) {
          return allData[i];
        }
      }
    }
  }

  var renderSelectorsList = () => {
    if (partsSelector === "cat") {
      /**
       * ? THIS BLOCK IS TO RENDER THE MAIN CATEGORIES WHICH WILL TAKE THE USER TO THE SEARCH PAGE
       *
       * */

      return catSelectors.slice(0, 20).map((item, index) => {
        let categoryLink = "/spares/" + item.slug;

        return (
          <>
            {index == 19 ? (
              <div className="footerLinks col-3 mb-2">
                <a href="/spares" style={{ color: "red" }}>
                  See all Spares +
                </a>
              </div>
            ) : (
              <div className="footerLinks col-3 mb-2">
                <a href={categoryLink}>{item.name}</a>
              </div>
            )}
          </>
        );
      });
    } else if (partsSelector === "mach") {
      /**
       * ? THIS BLOCK IS TO RENDER THE SUB CATEGORIES FOR SHOP BY CATEGOROIES
       *
       * */

      return machSelectors.slice(0, 20).map((item, index) => {
        return (
          <>
            <div
              className="footerLinks  col-3  mb-2"
              // onClick={() => handleSubCategory(item.id)}
              onClick={() => {
                window.location =
                  "/spares/" +
                  item.name
                    ?.toLowerCase()
                    .split(" ")
                    .join("-");
              }}
            >
              <a href="#">{item.name}</a>
            </div>
          </>
        );
      });
    } else if (partsSelector === "popular") {
      return popularSelectors.map((item) => {
        return (
          <>
            <div
              className="footerLinks col-3 mb-2"
              style={{ textAlign: "center" }}
              onClick={() => handlePrice(item)}
            >
              <a href="#">{item}</a>
            </div>
          </>
        );
      });
    }
  };

  function handlePrice(item) {
    if (item.includes("BELOW")) {
      let data = item.split(" ");

      router.push({
        pathname: "/search",
        search: "?min=" + 0 + "&max=" + data[1],
      });
    } else if (item.includes("ABOVE")) {
      let data = item.split(" ");

      router.push({
        pathname: "/search",
        search: "?min=" + data[1] + "&max=" + 1000000,
      });
    } else {
      let data = item.split("-");

      router.push({
        pathname: "/search",
        search: "?min=" + data[0] + "&max=" + data[1],
      });
    }
  }

  var footer = () => {
    return (
      <div
        className="row footer-section w-100 m-0 p-0"
        style={{ width: "99vw" }}
      >
        <div className="col-md-6 row flex-row py-3">
          <div className="col-md-7 footer-links-subsection">
            <div>
              <img
                className="footerLogo img-fluid"
                src={footerlogo}
                alt="footer-logo"
              />
            </div>
            <p className="d-none d-sm-flex">
            We are an online retailer for industrial spares and tools. Our extensive list of tools and spares meets all your
             requirements. We have collaborated with various companies/manufacturers to ensure that you find the product you 
             are looking for. We reduce your internet search time by half by providing the largest collection of brands and 
             their product variants to choose from.{" "}
            </p>
          </div>
          <div className="col-md-5 footer-links-subsection ">
            <p className="fw-bold footerTitle">Contact Us</p>
            <p>
              445, Vellalore Road, Near Top Spin Tennis Court, Singanallur,
              Coimbatore – 641005, Tamil Nadu, India
            </p>
            <p className="mb-1">Mail: info@spares4india.com</p>
            <p>Helpline number: +91 8489018222</p>
            <div>
              <p className="fw-bold mb-1">
                Follow us:{" "}
                <a
                  href="https://www.instagram.com/spare_4_india_com?utm_medium=copy_link"
                  rel="noreferrer"
                  target="_blank"
                  className=""
                >
                  <FaInstagram size={25} className="footer-social-icon" />
                </a>
                <a
                  href="https://www.facebook.com/people/Spares4indiacom/100094609835293/"
                  rel="noreferrer"
                  target="_blank"
                  className="mx-3"
                >
                  <FaFacebook size={25} className="footer-social-icon" />
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* MOBILE */}
        <div className="footer-links-section-mob d-flex d-sm-none flex-column align-items-center w-screen">
          <div className="col-12 col-sm-6 row text-center py-2">
            <div className="col-12 col-sm-4 my-2">
              <div className="connect-with-us-section px-0">
                <div className="col-12">
                  <div
                    className="d-flex flex-row align-items-center justify-content-between w-50 font-weight-bold mb-3"
                    onClick={() => setQuickLinks(!quickLinks)}
                    style={{ fontSize: "18px" }}
                  >
                    <span style={{ marginRight: "10px" }}>Quick Links</span>{" "}
                    <FaAngleDown color="red" />
                  </div>
                  <div
                    style={{
                      display: quickLinks ? "block" : "none",
                    }}
                  >
                    <p
                      onClick={() => router.push("/about-us")}
                      className="footer-links"
                    >
                      About us
                    </p>
                    <p
                      onClick={() => router.push("/contact-us")}
                      className="footer-links"
                    >
                      Contact us
                    </p>
                    <p
                      onClick={() => router.push("/careers")}
                      className="footer-links"
                    >
                      Careers
                    </p>
                    <p
                      onClick={() => (window.location = "/faqs")}
                      className="footer-links"
                    >
                      FAQs
                    </p>
                    {/* <p
                      onClick={() => (window.location = "/blog")}
                      className="footer-links"
                    >
                      Blogs
                    </p> */}
                    <p
                      onClick={() => (window.location = "/cancellation-return")}
                      className="footer-links"
                    >
                      Cancellation &amp; Return
                    </p>
                    <p
                      onClick={() => (window.location = "/terms-conditions")}
                      className="footer-links"
                    >
                      Terms &amp; Conditions
                    </p>
                    <p
                      onClick={() => (window.location = "/privacy-policy")}
                      className="footer-links"
                    >
                      Privay Policy
                    </p>
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-between w-50  font-weight-bold mb-3 mt-4"
                  onClick={() => setCat(!catShow)}
                  style={{ fontSize: "18px" }}
                >
                  <span style={{ marginRight: "10px" }}>Category</span>{" "}
                  <FaAngleDown color="red" />
                </div>
                <div
                  style={{
                    display: catShow ? "block" : "none",
                  }}
                >
                  <div className="col-12 col-sm-8 text-start">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <p>
                          {catSelectors.map((item, index) => {
                            let categoryLink = "/spares/" + item.slug;

                            return (
                              <>
                                <p className="footer-links">
                                  <a href={categoryLink}>{item.name}</a>
                                </p>
                              </>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 row text-center py-2 ps-4 d-none d-sm-flex">
          <div className="col-12 col-sm-4 my-2">
            <div className="connect-with-us-section px-0">
              <p className="fw-bold footerTitle">Quick Links</p>
              <p
                onClick={() => router.push("/about-us")}
                className="footer-links"
              >
                About us
              </p>
              <p
                onClick={() => router.push("/contact-us")}
                className="footer-links"
              >
                Contact us
              </p>
              <p
                onClick={() => router.push("/careers")}
                className="footer-links"
              >
                Careers
              </p>
              <p
                onClick={() => (window.location = "/faqs")}
                className="footer-links"
              >
                FAQs
              </p>
              {/* <p
                onClick={() => (window.location = "/blog")}
                className="footer-links"
              >
                Blogs
              </p> */}
              <p
                onClick={() => (window.location = "/cancellation-return")}
                className="footer-links"
              >
                Cancellation &amp; Return
              </p>
              <p
                onClick={() => (window.location = "/terms-conditions")}
                className="footer-links"
              >
                Terms &amp; Conditions
              </p>
              <p
                onClick={() => (window.location = "/privacy-policy")}
                className="footer-links"
              >
                Privay Policy
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-8 my-2 text-start">
            <div className="row">
              <div className="col-12 col-sm-6">
                <p className="fw-bold footerTitle">Category</p>
                <p>
                  {catSelectors.slice(0, 5).map((item, index) => {
                    let categoryLink = "/spares/" + item.slug;

                    return (
                      <>
                        <p className="footer-links">
                          <a href={categoryLink}>{item.name}</a>
                        </p>
                      </>
                    );
                  })}
                </p>
              </div>
              <div className="col-12 col-sm-6 mobileSecondSection">
                <p className="fw-bold footerTitle mobileSecondSectionHeading"></p>
                <p>
                  {catSelectors.slice(5, 10).map((item, index) => {
                    let categoryLink = "/spares/" + item.slug;

                    return (
                      <>
                        {index == 4 ? (
                          <p className="footer-links">
                            <a href="/spares" style={{ color: "red" }}>
                              See all Spares +
                            </a>
                          </p>
                        ) : (
                          <p className="footer-links">
                            <a href={categoryLink}>{item.name}</a>
                          </p>
                        )}
                      </>
                    );
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <ThemeProvider theme={theme}>
                        <ChatBot steps={steps } {...config}/>
                        
                    </ThemeProvider> */}
      </div>
    );
  };

  var footerBottom = () => {
    return (
      <div className="row footer-bottom-section py-1 w-100 m-0">
        <div className="col-12 col-md-6 footer-bottom-text-section text-md-left my-2 px-3">
          <span>Copyright © spares4india.com. All Right Reserved.</span>
          <br />
          <span>
            Developed by{" "}
            <a
              style={{ color: "var(--primary-red)", textDecoration: "none" }}
              href="http://www.wheelhousecentral.co/"
              target="_blank"
            >
              Wheelhouse
            </a>{" "}
            & Designed by{" "}
            <a
              style={{ color: "var(--primary-red)", textDecoration: "none" }}
              href=" https://therushrepublic.com/"
              target="_blank"
            >
              Rush Republic
            </a>{" "}
            2021.
          </span>
        </div>
        <div
          className="col-12 col-md-6 px-5 footer-bottom-text-section card-logos-section text-md-right my-2"
          style={{ textAlign: "right" }}
        >
          <span> We Accepted</span>&nbsp;
          <span>
            <img src={allCardsAccept} className="img-fluid" />
          </span>
        </div>
      </div>
    );
  };

  var renderCateogriesModal = () => {
    if (categoriesModal) {
      return <CategoriesModal toggleCategoriesModal={toggleCategoriesModal} />;
    }
  };

  var toggleCategoriesModal = () => {
    setCategoriesModal(!categoriesModal);
  };

  return (
    <div className="content-section">
      {/* {SparePartsExpert()} */}
      {footer()}
      {footerBottom()}
      {renderCateogriesModal()}
    </div>
  );
};

export default Footer;
