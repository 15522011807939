import React, { useEffect, useState } from "react";
// import  "../../../Assets/Styling/orders.css"

import Util from "../../utils/Util";
import API_Services from "../../utils/API_Services";
import UrlConstants from "../../utils/UrlConstants";
import { toast } from "react-toastify";
import Navbar from "Components/Navbar";
import Footer from "Components/Footer";
import ProfileSidebar from "./ProfileSidebar";
import { FaShoppingCart, FaFileDownload,FaTruck } from "react-icons/fa";
import "../../Assets/Styling/Profile/ViewOrder.css";
import "../../Assets/Styling/Profile/MyOrders.css";

import ComponentLoader from "../../Components/ComponentLoader";
// import RatingScale from "Components/RatingScale";

var Globals = require("../../utils/Globals");

export default function ViewOrder(props) {
	const [order, setOrder] = useState("");
	const [orderDetails, setOrderDetails] = useState([]);
	const [orderSummary, setOrderSummary] = useState({});
	const [ loader, setLoader ] = useState(true);

	useEffect(() => {

		fetchData();

	}, []);


	var fetchData = () => {

		fetchOrder();
		fetchOrderDetails();

	}

	var fetchOrder = () => {

		setLoader(true);

		var orderId = new URLSearchParams(props.location.search).get("id");

		if (orderId !== "" || orderId !== undefined) {

			var reqBody = {
				orderId: orderId,
			};

			API_Services.httpPOSTWithToken(
				UrlConstants.URLS.getOrder,
				reqBody,

				(err, response) => {

					console.log("FETCH ORDER RESPONSE: ", response);

					if (err) {

						toast.error("Something went wrong");

					}

					else if (response.data) {

						if (response.data.status && response.data.data) {

							setOrder(response.data.data);
							setOrderSummary(response.data.data.order_summary);
							setLoader(false);

						} else {

							toast.error("Something went wrong");

						}

					} else {

						toast.error("Something went wrong");

					}

				}
			)
		}
	};

	var fetchOrderDetails = () => {

		setLoader(true);

		var orderId = new URLSearchParams(props.location.search).get("id");

		if (orderId !== "" || orderId !== undefined) {

			var reqBody = {
				orderId: orderId,
				user_id:Util.getUserID()
			};

			API_Services.httpPOSTWithToken(
				UrlConstants.URLS.getOrderDetails,
				reqBody,

				(err, response) => {
					if (err) {
						toast.error("Something went wrong");
					}
					else if (response.data) {

						if (response.data.status && response.data.data) {

							console.log(response.data.data,"1111111111111111")
							setOrderDetails(response.data.data);

							setLoader(false);

						} else {

							toast.error("Something went wrong");

						}

					} else {

						toast.error("Something went wrong");

					}

				}
			)
		}
	};

	var orderHeadingTable = () => {


		if (order) {

			return (
				<div className="details-heading-table d-block d-lg-flex">
					<div className="vieworderDetails">
						<b>Order ID:</b> {order.id}

						<br />

							<b>Status:</b> { order.order_status.toLocaleUpperCase() }

						<br />

						<b>Phone no: </b> {order.delivery_address.phone_number}


					</div>
					<div className="vieworderDetails">
						<b>Ship to:</b>

						<br />

						{order.delivery_address.name}, <br />

						{`
									${order.delivery_address.address}, 
								`}

						<br />

						{`    
									${order.delivery_address.state} - ${order.delivery_address.postal_code}  
								`}

					</div>
				</div>
			);

		}


	}

	let setRatingByProductId = (rating,productId)=>{
		API_Services.httpPOSTWithToken(UrlConstants.URLS.addorEditRating
			,{rating:rating,product_id:productId},
			(err,response)=>{
				if(err){
					toast.error("Something went wrong");
				}

				if(response.data.status && response.data.rating){
					toast.success("Rating submitted")
				}
			})
	}
	var productsTableList = () => {
		let variantName  = (itemVariant)=>{
			let item  = {...itemVariant}
			delete item.mrpPrice;
			delete item.sellingPrice;
			delete item.variantStock;
		   return Object.values(item).reduce((acc,val,i)=>{return Object.values(item).length-1>i?acc+val+"X":acc+val},"");
		}
		var orderProducts = orderDetails;
		return orderProducts.map((product) => {
			let productDetails=orderSummary?.userCartObject?.products.find(i=>i.id==product.product_id);
			product["productDetails"]=productDetails.productDetails;
			console.log('====================================');
			console.log(product);
			console.log('====================================');
			return (
				<tr>
					<td className="image-cell px-2">
						<img
							src={product.product_image}
							className="product-img"
						/>
					</td>
					<td className="name-cell">
						{product.product_name} {" "}{product.variant?.variantName}
						<span>{product.productVariant && product.productVariant=='variation'?"("+variantName(product.variant)+")":""}</span>
						<br />

						<span className="price-splitup-text">Price: </span> Rs.{product.productVariant == 'variation'? product.variant.sellingPrice:product.selling_price}

						<br />

						<span className="price-splitup-text">Shipping Cost: </span> Rs.{product.shipping_cost}
						<br />
						{/* <RatingScale edit={true} 
						size="40" 
						value={product.isItemRatedByUser?product.rating:0}
						activeColor="#ff0000"
						
						setRating={(newValue)=>{
							setRatingByProductId(newValue,product.product.id)
						}} /> */}
					</td>
					<td className="qty-cell">{product.quantity}</td>
					<td className="price-cell">{(parseFloat(product.productVariant == 'variation'? product.variant.sellingPrice:product.selling_price) * product.quantity) + parseFloat(product.shipping_cost)}</td>

					<td className="text-end px-2">
						<button
							className="buy-it-again-btn"
							onClick={() => props.history.push(`/${product.productDetails?.category.name.toLowerCase().split(" ").join("-")}/${product.productDetails?.sub_categories.name.toLowerCase().split(" ").join("-")}/${product.productDetails.slug}`)}
						><FaShoppingCart className="mx-2" />Buy it again</button>
					</td>
				</tr>
			)

		});

	};

	function orderView() {

		return (
			<div class="view-order-container">

				<div className="w-100 d-block d-lg-flex flex-row align-items-center justify-content-between">
					<div></div><div className="btns-row-group">
					<a 
						href={`${Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].S3}pdf/orders/order-Invoice-${order.id}.pdf`}
						target="_blank"
						download
						className="order-invoice-btn d-inline-block d-lg-flex flex-row align-items-center"
						>
						<FaFileDownload className="mx-2"/>
						Order Invoice
					</a>
					{/* <a 
						href={`${Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].DELHIVERY_TRACK_URL}${order.waybillnumber}`}
						target="_blank"
						
						className="order-invoice-btn d-inline-block d-lg-flex flex-row align-items-center"
						>
						<FaTruck className="mx-1"/>
						Track Order
					</a> */}
					</div>
				</div>

				<br />

				{orderHeadingTable()}


				<div className="table-wrapper">
				<table className="details-products-table">
					<tr>
						<th className="image-cell px-2">Image</th>
						<th className="name-cell">Details</th>
						<th className="qty-cell">Qty</th>
						<th className="price-cell">Price</th>
						<th className="px-2"></th>
					</tr>

					{
						productsTableList()
					}

				</table>
				</div>
				

				<div className="w-100 d-flex flex-row align-items-center justify-content-end">
					<p className="summary-amt-text">
					<b>Sub Total: </b> {Util.currencyFormatter(orderSummary["subTotal"])}<br />
					<b>Shipping charges: </b> {Util.currencyFormatter(orderSummary["shippingCharges"])} <br />
					<b>Coupon Discount: </b> {Util.currencyFormatter(orderSummary["couponDiscount"])} <br />
					</p>
				</div>						
				<div className="w-100 d-flex flex-row align-items-center justify-content-end">
					<b>Total: &nbsp; </b> <h5>{Util.currencyFormatter(orderSummary["grandTotal"])}</h5> <br />
				</div>
					




			</div>
		);
	}

	function mainSectionInner() {

		var renderContent = () => {

			if(loader) {

				return <ComponentLoader />;

			} else {

				return(orderView())

			}

		};

		return (
			<div className="col-10 my-orders-inner pl-2">
				
				{ renderContent() }

			</div>
		);
	};

	function mainSection() {
		return (
			<div className="my-orders-main-section row">
				<ProfileSidebar props={props} />

				{mainSectionInner()}
			</div>
		);
	};


	return (
		<div>
			<Navbar /> <br />
			<div className="my-orders container-lg">
				{mainSection()}
			</div>
			<br />
			<Footer />
		</div>
	);
}
