import React from "react";
import { Route, Redirect } from "react-router-dom";
import Util from "../utils/Util";

const GuestRoute = ({ component: Component, ...rest }) => {

	// Admin can't access store front
	let isAdmin = false;

	let user = Util.getUser();

	if (user && user['userCoreProperties'] && user['userCoreProperties']['userRole'] === 1) {

		isAdmin = true;
	}

	return (
		<Route
			render={(props) =>
				isAdmin === true ? (
					<>
						<Redirect
							to={{
								pathname: "/admin-dashboard",
								state: { from: props.location },
							}}
						/>
					</>
				) : (
						<Component {...props} />
					)
			}
			{...rest}
		/>
	);
};

export default GuestRoute;
