import React, { useState } from "react";
import { toast } from "react-toastify";
import Login from "../Onboarding/Login";
import styles from "../../Assets/Styling/products.module.css";

const CategoriesSection = (props) => {
  const [loginModal, setLoginModal] = useState(false);

  function toggleLoginModal() {
    setLoginModal(!loginModal);
  }
  return (
    <div
      className={`${styles.wrapper} ${styles.categoriesItem} ${styles.wrapper_cat}`}
      onClick={() =>
        (window.location =
          "spares/" +
          props?.product?.name
            ?.toLowerCase()
            .split(" ")
            .join("-"))
      }
    >
      <img
        alt={props?.product?.name}
        src={props?.product?.path}
        className={styles.productImageCatImg}
      />

      <div className={styles.px1}>
        <span>{props?.product?.name}</span>
      </div>

      {loginModal && (
        <Login toggleLoginModal={toggleLoginModal} toast={toast} />
      )}
    </div>
  );
};

export default CategoriesSection;
