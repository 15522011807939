import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

var _ = require("lodash");
var Globals = require("./Globals");



const consentPropertyName = 'jdc_consent';

export default class Util {
	static verifyLoginStatus() {
		const token = !Util.isEmpty(localStorage.getItem("CORE_DATA")) || null;
		const isAuthenticated = token ? true : false;

		var loggedIn = false;

		if (isAuthenticated !== true) {
			loggedIn = false;
		} else {
			loggedIn = true;
		}

		return loggedIn;
	}

	static setAuthToken(token) {
		if (token) {
			return (axios.defaults.headers.common["Authorization"] = token);
		} else {
			return delete axios.defaults.headers.common["Authorization"];
		}
	}


	static getName() {

		var userData = localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA);

		var parsedData = JSON.parse(userData);

		return parsedData.userCoreProperties.name;

	}

	// Check mime type
	static checkMimeType(files) {
		//define message container
		let err = [];

		// list allow mime type
		const types = [
			"xls",
			"xlsx",
			"officedocument.spreadsheetml.sheet",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		];

		// loop access array
		for (var x = 0; x < files.length; x++) {
			// compare file type find doesn't matach
			if (types.every((type) => files[x].type !== type)) {
				// create error message and assign to container
				err[x] = files[x].name + " file is not in supported format\n";
			}
		}

		return err;
	}

	static isEmpty(value) {
		return (
			value === undefined ||
			value === null ||
			(typeof value === "object" && Object.keys(value).length === 0) ||
			(typeof value === "string" && value.trim().length === 0)
		);
	}

	static cloneArray(baseArray) {
		if (null == baseArray || "object" != typeof baseArray) return baseArray;
		var copy = baseArray.constructor();

		for (var attr in baseArray) {
			if (baseArray.hasOwnProperty(attr))
				copy[attr] = this.cloneArray(baseArray[attr]);
		}

		return copy;
	}

	static getUser() {
		return JSON.parse(
			localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA)
		);
	}

	static isSpecialUser() {

		var user = Util.getUser();

		var specialUser = false;

		if(user !== null) {

			specialUser = user?.userCoreProperties?.su

		}

		return specialUser;

	}

	static getUserID() {
		let userCoreProperties = JSON.parse(
			localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA)
		);

		let userID = "";

		if (userCoreProperties && userCoreProperties["userCoreProperties"]) {
			userID = userCoreProperties["userCoreProperties"]["userID"];
		}

		return userID;
	}

	static getUserEmail() {
		let userCoreProperties = JSON.parse(
			localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA)
		);

		let userEmail = "";

		if (userCoreProperties && userCoreProperties["userCoreProperties"]) {
			userEmail = userCoreProperties["userCoreProperties"]["userEmail"];
		}

		return userEmail;
	}

	static generateBaseAPIURLWithUserToken(userCoreProperties, endpoint) {
		var apiURL = endpoint;
		apiURL += "?userID=" + userCoreProperties?.userID;
		apiURL += "&accessToken=" + userCoreProperties?.accessToken;
		apiURL += "&platform=" + "web";

		return apiURL;
	}

	static validateUserName(username) {
		if (typeof username != "string" || username.match(/[^a-z0-9 ]/i))
			return false;
		else return true;
	}

	static validateName(username) {
		if (
			typeof username != "string" ||
			!username.match(/^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i)
		)
			return false;
		else return true;
	}

	static validateEmail(email) {
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	}

	static validateNumber(input) {
		var regex = /^[0-9\b]+$/;
		if (input !== "" && regex.test(input)) {
			return true;
		} else {
			return false;
		}
	}

	static validateCurrency(input) {
		var regex = /^[1-9]\d*((\.\d{0,2})?)$/;
		if (input !== "" && regex.test(input)) {
			return true;
		} else {
			return false;
		}
	}

	static validatePassword(pass) {
		var regex = new RegExp(
			"^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
		);
		return regex.test(pass);
	}

	static setCookie(cName, cValue) {
		cookies.set(cName, cValue, { Domain: "*.triumphhq.com", path: "/" });
	}

	static getCookie(cName) {
		return cookies.get(cName);
	}

	static removeCookie(cName) {
		cookies.remove(cName);
	}

	static shouldShowPopup(){return !this.getCookie(consentPropertyName);}
	static saveToStorage(){ 
		return this.setCookie(consentPropertyName, true);}

	static getQueryStringParams(query) {
		return query
			? (/^[?#]/.test(query) ? query.slice(1) : query)
				.split("&")
				.reduce((params, param) => {
					let [key, value] = param.split("=");
					params[key] = value
						? decodeURIComponent(value.replace(/\+/g, " "))
						: "";
					return params;
				}, {})
			: {};
	}

	static generateGUID() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	static generateUID() {
		return (
			this.generateGUID() +
			this.generateGUID() +
			"-" +
			Date.now() +
			"-" +
			this.generateGUID() +
			"-" +
			Date.now()
		);
	}

	static generateObjectId = (
		m = Math,
		d = Date,
		h = 16,
		s = (s) => m.floor(s).toString(h)
	) =>
		s(d.now() / 1000) +
		" ".repeat(h).replace(/./g, () => s(m.random() * h));

	static currencyFormatter(val) {

		let x = parseFloat(val).toFixed(2);
		x=x.toString();
		var afterPoint = '';
		if(x.indexOf('.') > 0)
		afterPoint = x.substring(x.indexOf('.'),x.length);
		x = Math.floor(x);
		x=x.toString();
		var lastThree = x.substring(x.length-3);
		var otherNumbers = x.substring(0,x.length-3);
		if(otherNumbers != '')
			lastThree = ',' + lastThree;
		var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;

		// let type = "INR";
		// val = String(val)
		// const dec =
		// 	String(val)?.length > 6
		// 		? val?.slice(val.length - 3, val.length)
		// 		: "";
		// if (String(val).length > 6) {
		// 	// console.log(val, "val");
		// 	val = val
		// 		?.slice(0, val.length - 3)
		// 		.split("")
		// 		.reverse()
		// 		.join("");
		// 	if (type === "INR") {
		// 		var l3 =
		// 			"," +
		// 			val
		// 				?.slice(0, 3)
		// 				.split("")
		// 				.reverse()
		// 				.join("");
		// 		val = val?.slice(3);
		// 		val =
		// 			String(val)
		// 				.match(/.{1,2}/g)
		// 				.join(",")
		// 				.split("")
		// 				.reverse()
		// 				.join("") + l3;
		// 	} else if (type === "USD") {
		// 		val = String(val)
		// 			.match(/.{1,3}/g)
		// 			.join(",")
		// 			.split("")
		// 			.reverse()
		// 			.join("");
		// 	}
		// }
		return Globals["CURRENCY"] + res;
	}
}