import React, { useState } from "react";
// import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import Categories from "./landingpage/leftSide/Categories";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Commitments from "../Components/Commitments";
import "../Assets/Styling/BlogGridView.css";

var UrlConstants = require("../utils/UrlConstants");
var _ = require("lodash");

const PrivacyPolicy = () => {
  const [open, setOpen] = useState(false);

  const headingSection = () => {
    return (
      <div className="heading-section-all">
        <h1>Privacy Policy</h1>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <Container fluid="true" className="bloggridview privacyPage mb-5 pb-2 content-section">
        <div style={{ display: "flex" }}>
          <div
            className="category-left-section"
            style={{ width: "290px", marginRight: "1rem" }}
          >
            <Categories />
          </div>
          <div className="main-right-section">
            {headingSection()}
            <div className="mainCareersLayout mt-4">
              <p>
                Spares4India values and respects the privacy of every individual
                who visits our website. We have created this Privacy Statement
                to inform you of the information collection practices for this
                Site, including what type of information is gathered, how the
                information is used, and with whom the information is shared.
                Please read this Privacy Statement carefully and revisit it from
                time to time as it may periodically be revised. By using this
                website, you indicate your understanding and acceptance of these
                terms. If you do not accept these terms, you may not use this
                website.
              </p>
              <p className="headingCancel">About this Privacy Policy</p>
              <p>
                This Privacy Policy describes the privacy practices of
                Spares4India and applies to the website owned and operated by
                Spares4India. This Privacy Policy describes the types of
                information we collect for business purposes, discusses how we
                collect, use, and share information to meet our customers’
                needs, and explains your rights and responsibilities related to
                the security of your personal information. If you have any
                questions or concerns regarding this Privacy Policy or our
                company’s privacy practices, please contact us using the
                information provided at the end of this document.
              </p>
              <p className="headingCancel">
                Where Spares4India Gets Personal Information
              </p>
              <p>
                Spares4India may collect information from one or more of the
                following sources:
              </p>
              <ul>
                <li>You</li>
                <li>Your electronic devices and technology</li>
                <li>Other third party sources</li>
              </ul>
              <p>
                You may share information with us in a form or online field when
                you create accounts on our website, when you visit our store, or
                when you sign up for one of our email lists to receive updates
                on our products and services. Your browser may also record
                pieces of information when you visit our website. We may receive
                information from other sources, as well, to help us personalize
                your shopping experience and protect you, and our company, from
                fraudulent activities. We strive to collect only the information
                necessary to support our business and provide you with unmatched
                automotive products and services.
              </p>
              <p>
                Spares4India maintains a strict “no-spam” policy. Subscribers to
                our e-mail services (or any other feature/service found on our
                website) will not receive unsolicited e-mail messages from us.
                We collect information online primarily to provide our visitors
                with a more relevant experience on our sites. When doing so, we
                will make every reasonable effort to avoid excessive or
                irrelevant collection of data. We will take reasonable physical,
                electronic and managerial measures to safeguard and secure any
                information you provide to us (eg. data will be stored in
                protected databases on secured servers with restricted access).
                We will not share any information you’ve provided to us with
                anyone without your consent other than to provide the
                information or service that you requested. Subsequent sections
                of this Privacy Policy will provide more detail about each of
                the ways you may provide information to us.
              </p>
              <p className="headingCancel">Information You Provide</p>
              <p>We may collect information from you in the following ways:</p>
              <ul>
                <li>
                  Payment information from credit, debit, and gift cards, as
                  well as personal and business checks, such as your name,
                  address, and bank account number, for processing payments
                </li>
                <li>
                  Background information from a commercial account application
                  or resale certificate
                </li>
                <li>
                  Usernames, passwords, and contact information from
                  Spares4India online accounts When you pay with a personal or
                  business check at our retail location, we will check your
                  name, address, and other personally identifiable information,
                  and then confirm that these details match your state-issued
                  ID, such as a driver’s licence or permit. Your driver’s
                  licence or permit number will be recorded on your personal and
                  business check for verification and security purposes.
                </li>
              </ul>
              <p className="headingCancel">
                Information Received Through Technology
              </p>
              <p>
                You may also provide information to us through your electronic
                devices. Spares4India uses technology to provide online customer
                support and data to improve your shopping experience. These
                technologies help us track and analyze shopping trends,
                administer and update our websites, and review user activity to
                improve our performance. This information helps us understand
                your needs and suggest products and services relevant to you.
              </p>
              <p>
                Spares4India gathers certain online and device information
                automatically and stores it in log files. This information may
                include your IP address, browser type, Internet service
                provider, and operating system, as well as landing and exit
                pages, date and time stamps, and clickstream data.
              </p>
              <p className="headingCancel">Cookies</p>
              <p>
                Spares4India uses cookies to track your shopping cart, remember
                your user settings, develop targeted marketing messages, and
                authenticate your account when you sign in. Cookies accomplish
                this by retaining your information from previous website visits
                and suggesting products and services as you browse and return to
                our website. You can control or disable the use of cookies
                within your browser. You can still use our websites if you
                disable cookies, but you may lose some features, or some
                sections of our websites may have limited functionality.
              </p>
              <p>
                Web beacons let us know if you visited a certain page on a
                website, opened a marketing email, or clicked on an
                advertisement. Tracking this information helps us understand
                which content is personally engaging and most relevant to you.
              </p>
              <p className="headingCancel">“Do Not Track” Feature</p>
              <p>
                Some browsers incorporate a “Do Not Track” (DNT) feature that,
                when turned on, signals to websites and online services that you
                do not want to be tracked. Because there is not yet an accepted
                standard for how to respond to a DNT signal, we do not currently
                respond to DNT signals on this website.
              </p>
              <p className="headingCancel">How Spares4India Uses Information</p>
              <p>
                Your information helps us personalize products and services to
                meet your needs. We only use your personal information to
                support and further our business functions, such as filling your
                orders, preventing fraud, creating targeted marketing efforts,
                and complying with legal requirements.
              </p>
              <p>Spares4India uses information in the following ways:</p>
              <ol>
                <li>
                  Fulfilling and communicating with you about your orders or
                  requests
                </li>
                <br />
                <li>Processing refunds, returns, and exchanges</li>
                <br />
                <li>Registering and servicing online accounts</li>
                <br />
                <li>
                  Administering contests, sweepstakes, promotions, and surveys
                </li>
                <br />
                <li>
                  Personalizing our service offerings, websites, and
                  advertisements
                </li>
                <br />
                <li>
                  Improving your experience in our store and on our website
                </li>
                <br />
              </ol>
              <p className="headingCancel">
                How Spares4India Shares Information
              </p>
              <p>
                Spares4India will never sell, rent, trade, or collect payment
                for sharing your personal information with third parties. We
                primarily share your information with third parties to perform
                the following services on your behalf:
              </p>
              <ol>
                <li>
                  Fulfilling your orders and mailing packages to your location
                </li>
                <br />
                <li>
                  Sending marketing communications and promotions relevant to
                  you
                </li>
                <br />
                <li>
                  Conducting research and analysis to improve our services and
                  products
                </li>
                <br />
              </ol>
              <p>
                We will share your personal information if we are required to do
                so by law or judicial or administrative order. We may also share
                information with appropriate authorities if we deem it necessary
                to protect Spares4India employees, customers, stakeholders, and
                other related parties, or if we become aware of a violation of
                law.
              </p>
              <p className="headingCancel">Your Privacy Preferences</p>
              <p>
                You may at any time change one or more of the following privacy
                preferences:
              </p>
              <ol>
                <li>Update your personal information</li>
                <br />
                <li>
                  Subscribe or unsubscribe from marketing and promotional emails
                </li>
                <br />
                <li>Deactivate your account</li>
                <br />
              </ol>
              <p>
                To ensure that we have the most recent information available, we
                recommend that you update your digital profiles with
                Spares4India whenever your information changes. You can keep
                your information current by taking the following actions:
              </p>
              <ol>
                <li>
                  Follow instructions included in emails that you are certain
                  came from Spares4India
                </li>
                <br />
                <li>
                  Sign in or register for an online account and make sure we
                  have the most recent contact information on file for you
                </li>
                <br />
                <li>
                  Contact us directly by phone or email and provide your most
                  recent mailing address, phone number, or email address
                </li>
                <br />
              </ol>
              <p>
                We can help you keep your information safe on Spares4India
                websites, but we cannot guarantee complete removal of your
                content and personal information from the Internet.
              </p>
              <p className="headingCancel">
                How Spares4India Keeps Your Personal Information Secure
              </p>
              <p>
                Whether you are shopping using our website or physical store,
                our associates utilize security measures to prevent unauthorized
                access to your personal information. These measures may include
                security access controls, information security technologies and
                policies, information disposal procedures, and participation in
                security training programs. The following are examples of
                security measures we use to protect your personal information:
              </p>
              <ol>
                <li>
                  Passwords to control access to your online account and help
                  protect your information (Please keep all of your online
                  passwords confidential)
                </li>
                <br />
                <li>
                  Monitoring policies and security systems to prevent
                  unauthorized access to locations where confidential
                  information might be stored
                </li>
                <br />
              </ol>
              <p>
                Unfortunately, even the best security measures cannot protect
                information from all unauthorized access. While we take the
                security of your personal information very seriously and have
                safeguards in place to protect it, Spares4India cannot be
                responsible for any damages you incur which are associated with
                sharing personally identifiable information with Spares4India.
              </p>
              <p className="headingCancel">Data Retention</p>
              <p>
                Spares4India will retain your information in order to provide
                you with the best products and services. We may also retain and
                use your information to fulfill legal obligations, resolve
                disputes, and enforce agreements. If you would like to cancel
                your account or request that we no longer use your information
                to provide you with products and services, please contact us as
                soon as possible at the address provided at the end of this
                Privacy Policy.
              </p>
              <p className="headingCancel">Privacy Policy Updates</p>
              <p>
                Spares4India may update this Privacy Policy at any time, at our
                sole discretion. You should check this Privacy Policy
                periodically for relevant updates. We may post a notice on our
                website prior to these changes taking effect, but you will
                always find the dates of publication and revision posted as part
                of this Privacy Policy. You should be mindful of any updates or
                modifications to this Privacy Policy and learn more about how
                these changes may affect the use of your personally identifiable
                information as a result.
              </p>
              <p className="headingCancel">How to Contact Spares4India</p>
              <p>
                If you have any questions about our privacy policy or terms of
                use, please contact us.
              </p>
              <p>Phone: +91 8489018222</p>
              <p>
                Address: No.448, Vellalore Road, Singanallur, Coimbatore, Tamil
                Nadu- 641005
              </p>
              <p>Email ID – info@spares4india.com</p>
            </div>
          </div>
        </div>
      </Container>
      <div className="px-4 content-section">
        <Commitments />
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
