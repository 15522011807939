import React, { useEffect, useState } from "react";
import Util from "../utils/Util"
import "../Assets/Styling/AcceptCookies.css";
// const cookieStorage = {
//     getItem: (item) => {
//         const cookies = document.cookie
//             .split(';')
//             .map(cookie => cookie.split('='))
//             .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
//         return cookies[item];
//     },
//     setItem: (item, value) => {
//         document.cookie = `${item}=${value};`
//     }
// }

const AccepCookies = ()=>{
    const [ishidden,setIshidden]=useState(true);

    const acceptCookie = event => {
        Util.saveToStorage();
        setIshidden(true)
    }
    useEffect(()=>{
        if (Util.shouldShowPopup()) {
            setTimeout(() => {
               setIshidden(false)
            }, 2000);
        }
    },[])
   


    return (
        <div id="consent-popup" className={ishidden?'hidden':''}>
        <p>This website uses cookies to ensure you get the best experience on our website.<a href="/privacy-policy">Privacy Policy</a>.
        </p>
        <button onClick={acceptCookie} className="accept" >Accept All</button>
    </div>
    )
}

export default AccepCookies;