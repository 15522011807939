export const dejaVu = {
    bgColor1: 'rgba(252, 82, 150, 0.8)',
    bgColor2: 'rgba(246, 112, 98, 0.8)',
    fontColor: 'rgba(19, 15, 64)',
    fontColorSelected: 'rgba(255, 255, 255)',
    dividerColor: 'rgba(225, 112, 85)',
    selectedBackgroundCollapsedMode: 'dark'
  }
  
  export const swampy = {
    bgColor1: 'rgba(11, 171, 100, 0.8)',
    bgColor2: 'rgba(59, 183, 143, 0.8)',
    fontColor: 'rgba(22, 46, 39)',
    fontColorSelected: 'rgba(255, 255, 255)',
    dividerColor: 'rgba(122, 204, 178)',
    selectedBackgroundCollapsedMode: 'light'
  }
  
  export const pinkAndBlue = {
    bgColor1: 'rgba(126, 232, 250, 0.8)',
    bgColor2: 'rgba(238, 192, 198, 0.8)',
    fontColor: 'rgba(150, 93, 105)',
    fontColorSelected: 'rgba(33, 22, 24)',
    dividerColor: 'rgba(232, 213, 216)',
    selectedBackgroundCollapsedMode: 'dark'
  }
  
  export const julyBlue = {
    bgColor1: 'rgba(100, 125, 238, 0.8)',
    bgColor2: 'rgba(127, 83, 172, 0.8)',
    fontColor: 'rgba(19, 15, 64)',
    fontColorSelected: 'rgba(255, 255, 255)',
    dividerColor: 'rgba(169, 139, 199)',
    selectedBackgroundCollapsedMode: 'dark'
  }
  
  export const preDark = {
  bgColor1: 'rgba(67, 67, 67, 0.8)',
  bgColor2: 'rgba(0, 0, 0, 0.8)',
  fontColor: 'rgba(161, 161, 161)',
  fontColorSelected: 'rgba(255, 255, 255)',
  dividerColor: 'rgba(48, 48, 48)',
  selectedBackgroundCollapsedMode: 'light'
  }
  
  export const brown = {
  bgColor1: 'rgba(149, 59, 32, 0.8)',
  bgColor2: 'rgba(87, 15, 10, 0.8)',
  fontColor: 'rgba(240, 178, 175)',
  fontColorSelected: 'rgba(255, 255, 255)',
  dividerColor: 'rgba(120, 64, 61)',
  selectedBackgroundCollapsedMode: 'dark'
  }
  
  export const silver = {
  bgColor1: 'rgba(91, 100, 103, 0.8)',
  bgColor2: 'rgba(47, 67, 83, 0.8)',
  fontColor: 'rgba(176, 176, 176)',
  fontColorSelected: 'rgba(255, 255, 255)',
  dividerColor: 'rgba(94, 104, 107)',
  selectedBackgroundCollapsedMode: 'dark'
  }