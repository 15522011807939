import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import {
  FaSearch,
  FaUser,
  FaGripLines,
  FaTimes,
  FaPhoneAlt,
} from "react-icons/fa";
// import { HiLocationMarker } from "react-icons/hi";
// import { BsChevronDown } from "react-icons/bs";
import { IoCart } from "react-icons/io5";

import "../Assets/Styling/Navbar.css";
import logo from "../Assets/Images/spares-logo.png";
import userPng from "../Assets/Images/header/profile.png";
import cartPng from "../Assets/Images/header/cart.png";
import Login from "../Pages/Onboarding/Login";
import Signup from "../Pages/Onboarding/Signup";
import Categories from "../Components/Categories";

import { productService } from "../services";

import Util from "../utils/Util";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";

import API_Services from "../utils/API_Services";
import UrlConstants from "../utils/UrlConstants";
import Logoutmodal from "./LogoutModal";
import Globals from "../utils/Globals";

const jwt = require("jsonwebtoken");

var Navbar = (props) => {
  // ? React-router History for redirection
  const history = useHistory();

  // ? STATE VARIABLES
  const [categories, setCategories] = useState([]);

  const [mobileNav, setMobileNav] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [categoriesModal, setCategoriesModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isModal, setIsModal] = useState(false);

  const [gstModalPhoneNumber, setGstModalPhoneNumber] = useState("");
  const [gstModalCompany, setGstModalCompany] = useState("");
  const [gstModalGst, setGstModalGst] = useState("");
  const [gstModalError, setGstModalError] = useState("");
  const [gstModal, setGstModal] = useState(false);
  const [signedUpNow, setSignedUpNow] = useState(false);
  const [cartItemNumber, setCartItemNumber] = useState(0);
  // ? NON-RENDERING METHODS

  // ? Method to toggle the Login Modal
  var toggleSignupModal = () => {
    setSignupModal(!signupModal);
  };

  // ? Method to open Login modal from Signup
  var openLoginFromSignup = () => {
    toggleSignupModal();
    toggleLoginModal();
  };
  // * End of SIGNUP MODAL

  // ? Handle cart page redirection
  var cartPageRedirection = () => {
    let verifyLoginStatus = Util.verifyLoginStatus();

    if (verifyLoginStatus) {
      window.location = "/cart";
    } else {
      toggleLoginModal();
    }
  };

  // * END OF NON-RENDERING METHODS

  // ? RENDERING METHODS

  // ? Redering the navbar for desktop view
  var desktopNavBar = () => {
    return (
      <div class="container-fluid content-section desktop-navbar" >
        <div className="d-block d-lg-block" style={{position: 'relative'}}>{navBarTopSection()}</div>
      </div>
    );
  };
  //  * End of desktopNavbBar()

  // ? Method to reder Signin button if user is not logged in else render the my profile button
  var desktopSigninProfile = () => {
    var loginStatus = Util.verifyLoginStatus();

    var loginBtn = () => {
      if (!loginStatus) {
        return (
          <p
            className="text-center w-100 mb-0 login-signup-btn"
          >
            <span className="cursor-ptr" onClick={() => toggleLoginModal()}>Login / Signup</span>
          </p>
        );
      }
    };

    if (loginStatus) {
      return (
        <div className="navProfileButton profile-icon-section d-flex flex-row align-items-center mx-2 px-3 py-1">
          <div className="profile-icon d-flex flex-row align-items-center justify-content-center mx-1">
            <img src={userPng} />
          </div>
          {/* &nbsp; Profile */}
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Hi <span style={{ color: "#fff" }}>{Util.getName()}</span> !
            </Dropdown.Toggle>

            <Dropdown.Menu className="profile-dropdown">
              <Dropdown.Item
                onClick={() => (window.location = "/account/profile")}
              >
                My Account
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => (window.location = "/account/my-orders")}
              >
                My Orders
              </Dropdown.Item>

              <Dropdown.Item onClick={() => (window.location = "/contact-us")}>
                Spares Expert
              </Dropdown.Item>

              <Dropdown.Item onClick={() => setIsModal(!isModal)}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else if (!loginStatus) {
      return loginBtn();
    }
  };
  // * End of desktopSigninProfile()

  // ? Rendering the Navbar top section

  function handleSearch() {
    // history.push("/search/" + searchValue + "/category/" + "all");
    if (searchValue.length == 0 || searchValue.length > 1) {
      window.location = "/search?searchValue=" + searchValue;
    }
  }

  function handleSearchValue(val) {
    setSearchValue(val);

    // setTimeout(() => {
    //     if (props && Object.keys(props).length > 0) {
    //         props?.handleSearch(val);
    //     }
    // }, 3000);
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  // Show no of items in the cart - start
  let getItemInCart = () => {
    let reqBody = {};
    API_Services.httpPOSTWithToken(
      UrlConstants.URLS.cartList,
      reqBody,
      (err, response) => {
        if (err) {
          if (err.response && err.response.data) {
            // toast.error(err.response.data.message);
          } else {
            // toast.error("Something went wrong!");
            console.log("err...", err);
          }
        } else if (response.data) {
          if (response.data.status) {
            let data = response.data.data;

            if (data) {
              setCartItemNumber(data?.userCartObject?.products.length);
            }
          } else {
            // toast.error(response.data.message);
          }
        } else {
          // toast.error("Something went wrong!");
        }
      }
    );
  };

  // Show no of items in the cart -end
  var renderDropdownOptions = () => {
    return categories.map((category) => {
      return (
        <Dropdown.Item
          onClick={() => {
            // history.push({
            //     // pathname: "/search",
            //     // search: "?category=" + category.id,
            //     pathname: "/category/" + category.slug,
            // })
            
            {
              window.location = "/spares/" + category.slug;
            }
          }}
        >
          <span className="dropDownCategoryList"> {category.name} </span>
        </Dropdown.Item>
      );
    });
  };

  // * NAVBAR FOR THE DESKTOP VIEW
  var navBarTopSection = () => {
    return (
      <div className="row navbar-top-section">
        <div className="col-3 logo-section px-0">
          <div className="navbar-logo align-middle d-flex flex-row align-items-center">
            <img
              src={logo}
              className="logo"
              onClick={() => {
                window.location.href = "/";
              }}
              alt=""
            />
          </div>
        </div>
        <div className="col-5 search-section d-flex flex-row align-items-center justify-content-center">
          <div
            className="categories-dropdown-nav d-flex flex-row align-items-center justify-content-around"
            // onClick={() => toggleCategoriesModal()}
          >
            {/* Categories */}
            {/* <BsChevronDown style={{ margin: "0 0 0 1rem" }} /> */}
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">All Category</Dropdown.Toggle>

              <Dropdown.Menu>{renderDropdownOptions()}</Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="navbar-search-bar d-flex flex-row align-items-center justify-content-center">
            <input
              placeholder="Search"
              className="navbar-search-input"
              onChange={(e) => handleSearchValue(e.target.value)}
              value={searchValue}
              onKeyDown={handleKeyDown}
            />
            <FaSearch className="search-icon" onClick={() => handleSearch()} />
          </div>
        </div>
        <div className="col-4 icons-section login-cart-section d-flex flex-row align-items-center justify-content-end" >
          {desktopSigninProfile()}

          {/* <div className="profile-icon-section d-flex flex-row align-items-center mx-2">
                        <div className="profile-icon d-flex flex-row align-items-center justify-content-center mx-1">
                            <HiLocationMarker className="icon_Style" />
                        </div>
                        &nbsp; Track Order
                    </div> */}
          <div
            className="profile-icon-section d-flex flex-row align-items-center mx-1"
            ca
            onClick={() => cartPageRedirection()}
          >
            Cart {Util.verifyLoginStatus() && (
              <div className="cart-items">
                {props.itemsInCart || props.itemsInCart == 0
                  ? props.itemsInCart
                  : cartItemNumber}
              </div>
            )}
            <div className="cart-icon d-flex flex-row align-items-center justify-content-center mx-1">
              <img src={cartPng} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  // * End of navBarTopSection

  // ? Rendering the Navbar for mobile view
  var mobileNavBar = () => {
    return (
      <div className="d-block d-lg-none">
        <div className="">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="navbar-logo-mob d-flex flex-row justify-content-between py-2 px-3 align-items-center">
              <img
                src={logo}
                className="logo_mob"
                onClick={() => {
                  window.location.href = "/";
                }}
                alt=""
              />
              {/* <FaGripLines
                style={{ color: "var(--primary-grey-dark)" }}
                size={30}
                onClick={() => setMobileNav(!mobileNav)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  //  * End of mobileNavBar()

  // ? Rendering the expanded Mobile navbar
  var mobileNavBarExpanded = () => {
    var loginStatus = Util.verifyLoginStatus();

    var mobileNavRedirect = (slug) => {
      history.push(slug);
    };

    var mobileLoginBtn = () => {
      if (!loginStatus) {
        return (
          <p
            className="nav-mob-links text-center w-100"
            onClick={() => toggleLoginModal()}
          >
            LOGIN / SIGNUP
          </p>
        );
      }
    };

    var profileIcon = () => {
      if (loginStatus) {
        return (
          <div
            onClick={() => history.push("/account/profile")}
            className="profile-icon-mob d-flex flex-row align-items-center justify-content-center mx-3"
          >
            <FaUser size={30} />
          </div>
        );
      }
    };

    return (
      <div
        className={mobileNav ? "d-block d-lg-none mob-nav-opened py-3" : "none"}
        style={{
          display: mobileNav ? "block" : "none",
        }}
      >
        <div className="row ">
          <div className="col-12 d-flex flex-row align-items-center justify-content-end px-3 mb-3">
            <FaTimes
              size={30}
              onClick={() => {
                setMobileNav(!mobileNav);
              }}
            />
          </div>

          <div className="col-12 d-flex flex-row align-items-center justify-content-center">
            <input
              className="nav-search-input-mob w-75"
              placeholder="Search"
              onChange={(e) => handleSearchValue(e.target.value)}
              value={searchValue}
              onKeyDown={handleKeyDown}
            />
            <FaSearch
              onClick={() => {
                setMobileNav(!mobileNav);
                handleSearch();
              }}
              size={25}
            />
          </div>

          {/* <div className="col-12 text-center py-3">
                        <button className="all-cat-btn-mob py-3 w-75">
                            ALL CATEGORIES
                        </button>
                    </div> */}

          <hr className="nav-hr" />

          <div className="col-12 nav-mob-links-section d-flex flex-column align-items-center justify-content-center py-3">
            <p
              className="nav-mob-links text-center w-100"
              onClick={() => mobileNavRedirect("/")}
            >
              HOME
            </p>
            <p
              className="nav-mob-links text-center w-100"
              onClick={() => mobileNavRedirect("/about-us")}
            >
              ABOUT US
            </p>
            <p
              className="nav-mob-links text-center w-100"
              onClick={() => mobileNavRedirect("/contact-us")}
            >
              CONTACT US
            </p>
            {mobileLoginBtn()}
          </div>

          <hr className="nav-hr" />

          <div className="col-12 icons-section d-flex flex-row align-items-center justify-content-center mt-3">
            {profileIcon()}

            <div className="cart-icon-mob d-flex flex-row align-items-center justify-content-center mx-3">
              <IoCart size={30} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  // * End of mobileNavBarExpanded()

  // ? LOGIN MODAL

  // ? Method the render the Login modal
  var renderLoginModal = () => {
    if (loginModal) {
      return (
        <Login
          toggleLoginModal={toggleLoginModal}
          openSignupFromLogin={openSignupFromLogin}
          toast={toast}
        />
      );
    }
  };

  // ? Method to toggle the Login Modal
  var toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  // ? Method to open Signup modal from Login
  var openSignupFromLogin = () => {
    toggleLoginModal();
    toggleSignupModal();
  };

  // * End of LOGIN MODAL

  // ? SIGNUP MODAL

  // ? Method the render the Signup modal
  var renderSignupModal = () => {
    if (signupModal) {
      return (
        <Signup
          toggleSignupModal={toggleSignupModal}
          openLoginFromSignup={openLoginFromSignup}
          toast={toast}
          UserSignedUpNow={UserSignedUpNow}
        />
      );
    }
  };
  // * End of renderSignupModal()

  // * End of SIGNUP MODAL

  // ? CATEGORIES MODAL

  // ? Method to render the Categories modal/popup
  var renderCateogriesModal = () => {
    if (categoriesModal) {
      return "";
      // <Categories
      //     toggleCategoriesModal={toggleCategoriesModal}
      //     history={history}
      // />
    }
  };
  // * End of rederCategoriesModal()

  var toggleGstModal = () => {
    setGstModalPhoneNumber("");
    setGstModalCompany("");
    setGstModalGst("");
    setGstModalError("");
    setGstModal(!gstModal);
  };

  var UserSignedUpNow = () => {
    setSignedUpNow(true);
  };

  var renderGstModal = () => {
    return (
      <Modal isOpen={gstModal && signedUpNow} toggle={() => toggleGstModal()}>
        <ModalHeader toggle={() => toggleGstModal()}>
          <span className="edit-modal-heading">Enter Phone number </span>
        </ModalHeader>
        <ModalBody>
          <div className="edit-modal-body row">
            <div className="col-12">
              Phone number *: <br />
              <input
                value={gstModalPhoneNumber}
                className="edit-modal-input"
                placeholder="Phone Number"
                onChange={(event) => setGstModalPhoneNumber(event.target.value)}
              />
            </div>

            <div className="col-12">
              Company: <br />
              <input
                value={gstModalCompany}
                className="edit-modal-input"
                placeholder="Company"
                onChange={(event) => setGstModalCompany(event.target.value)}
              />
            </div>

            <div className="col-12">
              GST number: <br />
              <input
                value={gstModalGst}
                className="edit-modal-input"
                placeholder="Gst number"
                onChange={(event) => setGstModalGst(event.target.value)}
              />
            </div>

            <div className="col-12 gst-modal-error-text">{gstModalError}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              window.location = "/";
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => updatePhoneNumber()}>
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  var updatePhoneNumber = () => {
    const phoneNumberRegex = /^[6-9]\d{9}$/;
    const gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

    if (!gstModalPhoneNumber) {
      setGstModalError("* Phone number cannot be empty");
    } else if (gstModalCompany && !gstModalGst) {
      setGstModalError("* Please enter GST number");
    } else if (!phoneNumberRegex.test(gstModalPhoneNumber)) {
      setGstModalError("* Please enter a valid Phone number");
    } else if (gstModalCompany && !gstRegex.test(gstModalGst)) {
      setGstModalError("* Please enter a valid GST number");
    } else {
      var data = {
        phone_number: gstModalPhoneNumber,
      };

      if (gstModalCompany) {
        data["company"] = gstModalCompany;
        data["gst"] = gstModalGst;
      }

      setGstModalError("");

      API_Services.httpPOSTWithToken(
        UrlConstants.URLS.updatePhoneNumber,
        data,

        (err, response) => {
          if (err) {
            toast.error("Something went wrong");
          } else if (response.data && response.data.status) {
            // toggleGstModal();
            toast.success("Details updated successfully!");
            window.location = "/";
          } else {
            toast.error("Something went wrong");
          }
        }
      );
    }
  };

  // ? Method to toggle Categories Modal
  var toggleCategoriesModal = () => {
    setCategoriesModal(!categoriesModal);
  };
  // * End of toggleCategoriesModal()
  // * End of CATEGORIES MODAL

  //  * END OF RENDERING METHODS

  var checkIfUserSignedUp = () => {
    var url = new URL(window.location.href);

    var nu = url.searchParams.get("nu");

    if (nu === "1") {
      setGstModal(true);
      setSignedUpNow(true);
    }
  };

  var fetchMainCategories = async () => {
    let categories = await productService.getAllCategory();

    if (categories?.data?.data?.length > 0) {
      setCategories(categories?.data?.data);
    }
  };

  // ? COMPONENT LIFECYCLE METHODS

  useEffect(() => {
    // Update the document title using the browser API

    Util.verifyLoginStatus();
    if (Util.verifyLoginStatus()) getItemInCart();
    checkIfUserSignedUp();

    fetchMainCategories();
  }, []);

  // * END OF COMPONENT LIFECYCLE METHODS

  var closeModal = () => {
    setIsModal(false);
  };

  var logout = () => {
    setIsModal(false);
    localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA);
    localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES);
    localStorage.removeItem("sparesToken");
    window.location = "/";
  };

  return (
    <>
    <div className="navbar-container">
      <div className="topBarBg">
        <div className="headerTopBar container-fluid content-section">
          <div className="row">
            <div className="leftSidebar col-12 col-lg-6">
              <span>Welcome to Spares4india</span>
            </div>
            <div className="rightSidebar text-right col-12 col-lg-6">
              Helpline Number: <a href="tel:+91 84890 18222">
                +91 8489018222
              </a>
            </div>
          </div>
        </div>
      </div>
      <Logoutmodal
        isModal={isModal}
        closeModal={() => closeModal()}
        logout={() => logout()}
      />
      {renderLoginModal()}

      {renderSignupModal()}

      {renderGstModal()}

      {renderCateogriesModal()}

      {desktopNavBar()}
      {/* {mobileNavBar()} */}
      {/* {mobileNavBarExpanded()} */}
    </div>
    <div className="col-6 search-section d-flex flex-row align-items-center justify-content-center search-mobile">
          <div
            className="categories-dropdown-nav d-flex flex-row align-items-center justify-content-around"
            // onClick={() => toggleCategoriesModal()}
          >
            {/* Categories */}
            {/* <BsChevronDown style={{ margin: "0 0 0 1rem" }} /> */}
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">All Category</Dropdown.Toggle>

              <Dropdown.Menu>{renderDropdownOptions()}</Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="navbar-search-bar d-flex flex-row align-items-center justify-content-center">
            <input
              placeholder="Search"
              className="navbar-search-input"
              onChange={(e) => handleSearchValue(e.target.value)}
              value={searchValue}
              onKeyDown={handleKeyDown}
            />
            <FaSearch className="search-icon" onClick={() => handleSearch()} />
          </div>
        </div>
        <div className="col-3 icons-section d-flex flex-row align-items-center justify-content-end icons-mobile" >
          {desktopSigninProfile()}

          {/* <div className="profile-icon-section d-flex flex-row align-items-center mx-2">
                        <div className="profile-icon d-flex flex-row align-items-center justify-content-center mx-1">
                            <HiLocationMarker className="icon_Style" />
                        </div>
                        &nbsp; Track Order
                    </div> */}
          <div
            className="profile-icon-section d-flex flex-row align-items-center mx-1"
            ca
            onClick={() => cartPageRedirection()}
          >
            Cart {Util.verifyLoginStatus() && (
              <div className="cart-items">
                {props.itemsInCart || props.itemsInCart == 0
                  ? props.itemsInCart
                  : cartItemNumber}
              </div>
            )}
            <div className="cart-icon d-flex flex-row align-items-center justify-content-center mx-1">
              <img src={cartPng} />
            </div>
          </div>
        </div>
    </>
  );
};

export default Navbar;
