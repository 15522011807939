import React, { Component } from "react";

import Navbar from "../../Components/Navbar";
import Commitments from "../../Components/Commitments";
import Footer from "../../Components/Footer";

import "../../Assets/Styling/NotFound.css";

class NotFound extends Component {
  componentDidMount() {
    const { match } = this.props;
    if (match.params[0] == "/services" || match.params[0] == "/services/") {
      this.props.history.push("/");
    }
  }

  // ? METHOD TO RENDER THE NOT FOUND SECTION
  renderNotFoundSection = () => {
    return (
      <div className="not-found-wrapper content-section">
        <div className="not-found-section">
          <span className="not-found-text">
            <span className="error-code-text">404</span>
            <br />
            Page Not Found
            <br />
            <br />
            Back to{" "}
            <span
              onClick={() => {
                window.location = "/";
              }}
              className="home-link-text"
            >
              Home
            </span>
          </span>
        </div>
      </div>
    );
  };
  // * End of renderNotFoundSection()

  render() {
    return (
      <div>
        <Navbar />

        {this.renderNotFoundSection()}

        <br />
        <br />
        <div className="px-4 content-section">
          <Commitments />
        </div>
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default NotFound;
