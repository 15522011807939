import React from "react";
import { Route, Redirect } from "react-router-dom";
import Util from "../utils/Util";

const CustomerProtectedRoute = ({ component: Component, ...rest }) => {
	
	let isAuthenticated = false;

	let user = Util.getUser();

	if (user && user['userCoreProperties'] && user['userCoreProperties']['userRole'] === 2) {

		isAuthenticated = true;
	}

	return (
		<Route
			render={(props) =>
				isAuthenticated === true ? (
					<>
						<Component {...props} />
					</>
				) : (
						<Redirect
							to={{
								pathname: "/",
								state: { from: props.location },
							}}
						/>
					)
			}
			{...rest}
		/>
	);
};

export default CustomerProtectedRoute;
