import React from "react";

import delivery from "../Assets/Images/Commitments/free-delivery.png";
import payment from "../Assets/Images/Commitments/safe-payment.png";
import shop from "../Assets/Images/Commitments/shop-confidence.png";
import help from "../Assets/Images/Commitments/help-center.png";
import "../Assets/Styling/Home.css";

var Commitments = () => {
    return (
        <div className="commitments-section row">

            <div className="col-6 col-md-3 col-lg-3 commitment-block">
                <div className="img-content">
                    <img
                        src={payment}
                        alt="payment"
                        className="commitment-img"
                    />
                </div>
                <div className="commitments-content">
                    <p className="commitment-heading">Safe Payment</p>
                    <p className="commitment-text d-none d-md-block">
                	We have a safe payment gateway and do not capture any sensitive information such as card details or PINs.
                    </p>
                </div>
            </div>

            <div className="col-6 col-md-3 col-lg-3 commitment-block">
                <div className="img-content">
                    <img
                        src={delivery}
                        alt="delivery"
                        className="commitment-img"
                    />
                </div>
                <div className="commitments-content">
                    <p className="commitment-heading">Free Delivery</p>
                    <p className="commitment-text d-none d-md-block">
                    Get your products delivered to your doorstep at NO ADDITIONAL COSTS. We ship all over India.
                    </p>
                </div>
            </div>

            <div className="col-6 col-md-3 col-lg-3 commitment-block">
                <div className="img-content">
                    <img src={help} alt="help" className="commitment-img" />
                </div>
                <div className="commitments-content">
                    <p className="commitment-heading">Help Centre</p>
                    <p className="commitment-text d-none d-md-block">
                    Our team is available round the clock to help you find the right products; answer your queries and more.
                    </p>
                </div>
            </div>

            <div className="col-6 col-md-3 col-lg-3 commitment-block">
                <div className="img-content">
                    <img src={shop} alt="shop" className="commitment-img" />
                </div>
                <div className="commitments-content">
                    <p className="commitment-heading">Shop Confidence</p>
                    <p className="commitment-text d-none d-md-block">
                    We have taken the extra step to get a wide assortment of tools and parts available in the market.
                    </p>
                </div>
            </div>

            
        </div>
    );
};

export default Commitments;
