import { Col, Container, Row } from "react-bootstrap";
import styles from "../../Assets/Styling/search.module.css";
import Categories from "./CategoriesSection";

const RightSide = (props) => {
  return (
    <Container>
      <div className="d-flex flex-row align-items-center justify-content-start">
        <h1
          style={{ fontSize: "1rem" }}
          className={styles.categoryWord + " mx-2"}
        >
          Spares
        </h1>
        <hr style={{ border: "1px solid", width: "100%" }} />
      </div>

      {props?.data?.length > 0 ? (
        <Row>
          {props.data.map((product) => (
            <Col xl={3} sm={6}>
              <Categories product={product} />
            </Col>
          ))}
        </Row>
      ) : (
        <div className={styles.noSearchData}>No Categories</div>
      )}

      <br />

      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
          className="text-center"
        ></p>
      </div>
    </Container>
  );
};

export default RightSide;
