import './App.css';

import "./Assets/Styling/Constants.css";
import 'react-dropzone-uploader/dist/styles.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Bootstrap CSS file
import 'bootstrap/dist/css/bootstrap.min.css';

// REACT-TOASTIFY
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import RouterComponent from "./Components/RouterComponent";
import AccepCookies from 'Components/AcceptCookies';

var App = () => {
	return (
		<div className="App">
			
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
			/>
			<RouterComponent />
			<AccepCookies />
		</div>
	);
}

export default App;
