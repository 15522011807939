import styles from "../../../Assets/Styling/css/leftSide.module.css";
import "../../../Assets/Styling/css/leftSide.css";
import { useState, useEffect } from "react";
import { productService } from "services";
import CategoriesModal from "../../../Components/Categories";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { RiMenu2Fill } from "react-icons/ri";
import { FaInstagram, FaFacebook } from "react-icons/fa";

import { useHistory } from "react-router";

//
import API_Services from "../../../utils/API_Services";
import UrlConstants from "../../../utils/UrlConstants";
import { toast } from "react-toastify";
import SplashScreen from "Components/SplashScreen";

const Categories = (props) => {
  const [clicked, setClicked] = useState(0);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoriesModal, setCategoriesModal] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [categoryTree, setCategoryTree] = useState();
  const [panel, togglePanel] = useState(true);
  const router = useHistory();

  const [categoryCount, setCategoryCount] = useState("Home");
  const [device, setDevice] = useState("Home");
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setCategoryCount(6);
      togglePanel(false);
    } else {
      setCategoryCount(11);
      togglePanel(true);
    }

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
    if (isTablet) {
      setCategoryCount(6);
      togglePanel(true);
    }
  }, [categoryCount]);

  // useEffect(() => {}, [clicked]);

  useEffect(() => {
    getAllCategories();
    getAllSubCategories();
    getCategoryTree();
  }, []);

  function getCategoryTree() {
    API_Services.httpPOST(
      UrlConstants.URLS.getCategoryTree,
      {},
      (err, response) => {
        if (err) {
          if (err.response && err.response.data) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong!");
            console.log("err...", err);
          }
        } else if (response.data) {
          if (response.data.status) {
            let allTree = response.data.data;
            let ultrasonic = allTree.find(i=>i.nameId == "ultrasonics");
            let ultrasonicIndex = allTree.indexOf(ultrasonic);
            let ultrasonicSpares = ultrasonic.children.find(i=>i.nameId == "ultrasonic-spares");
            let ultrasonicSparesIndex = ultrasonic.children.indexOf(ultrasonicSpares);
            allTree[ultrasonicIndex].children.splice(ultrasonicSparesIndex,1)
            allTree[ultrasonicIndex].children.push(ultrasonicSpares)
            setCategoryTree(response.data.data);
            console.log(
              response.data.data,
              "treeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
            );
          } else {
            toast.error(response.data.message);
          }
        } else {
          toast.error("Something went wrong!");
        }
      }
    );
  }

  async function getAllCategories() {
    try {
      let categories = await productService.getAllCategory();

      if (categories?.data?.data?.length > 0) {
        setCategories(categories?.data?.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  var getAllSubCategories = () => {
    API_Services.httpPOSTWithToken(
      UrlConstants.URLS.getDistinctSubCategories,
      {},

      (err, response) => {
        if (err) {
          // toast.error("Something went wrong");
        } else if (response.data) {
          if (response.data.status && response.data.data) {
            setSubCategories(response.data.data);
            setLoader(false);
          } else {
            // toast.error("Something went wrong");
          }
        } else {
          // toast.error("Something went wrong");
        }
      }
    );
  };

  var renderCateogriesModal = () => {
    if (categoriesModal) {
      return (
        <CategoriesModal
          toggleCategoriesModal={toggleCategoriesModal}
          categoryId={categoryId}
          history={props?.props?.props?.props?.history}
        />
      );
    }
  };
  var toggleCategoriesModal = (id) => {
    setCategoryId(id);
    setCategoriesModal(!categoriesModal);
  };

  const onMouseOver = (event) => {
    const el = event.target;
    el.style.color = "white";
  };

  const onMouseOut = (event) => {
    const el = event.target;
    let black = "#000000";
    el.style.color = black;
  };

  var renderSubCategoriesList = () => {
    var subCategoriesList = subCategories.slice(0, 10);

    return subCategoriesList.map((subCategory) => {
      return (
        <>
          <div
            // // onClick={() => toggleCategoriesModal(item.id)}
            onClick={() => {
              router.push({
                pathname:
                  "/spares/" +
                  subCategory.name
                    ?.toLowerCase()
                    .split(" ")
                    .join("-"),
              });
            }}
            className="categoriesDivs"
            // style={clicked === 1 ? { color: "red" } : {}}
            onMouseEnter={(event) => onMouseOver(event)}
            onMouseOut={(event) => onMouseOut(event)}
          >
            <img
              className={styles.allCategoriesImg}
              src={subCategory.path}
              alt={subCategory.name}
            />
            <span className={styles.allCategoryName}>{subCategory.name}</span>
          </div>
        </>
      );
    });
  };

  return (
    <>
      {loader ? <SplashScreen /> : ""}
      <div className={styles.categoriesWrapper}>
        <button
          className={styles.allCategoryBtn}
          onClick={() => {
            togglePanel(!panel);
          }}
        >
          <RiMenu2Fill color="#ff0000" />{" "}
          <span style={{ color: "white" }}>All Category</span>
        </button>

        {panel && (
          <ul className="leftNavContainer">
            {categoryTree?.length > 0 &&
              categoryTree.slice(0, 11).map((item, index) => {
                return (
                  <li
                    // onClick={() => toggleCategoriesModal(item.id)}

                    className="categoriesDivs"
                    // style={clicked === 1 ? { color: "red" } : {}}
                    // onMouseEnter={(event) => onMouseOver(event)}
                    // onMouseOut={(event) => onMouseOut(event)}
                  >
                    <img
                      className={styles.allCategoriesImg}
                      src={item.path}
                      alt={item.name}
                      onClick={() => {
                        // router.push({
                        // pathname:
                        window.location = "/spares/" + item.slug;
                        // });
                      }}
                    />{" "}
                    <div
                      className={styles.allCategoryName}
                      onClick={() => {
                        // router.push({
                        // pathname:
                        window.location = "/spares/" + item.slug;
                        // });
                      }}
                    >
                      {item.name}
                    </div>
                    <div className={`sub-menu`}>
                    { item.children.length > 0 && <div className="megamenu">
                        {item.children.length > 0 &&
                          item.children.slice(0, 10).map((level1, index) => {
                            return (
                              <div
                                // onClick={() => toggleCategoriesModal(item.id)}

                                className={`${level1.children.length>0?'sub-item':'child-item'} `}
                                // style={clicked === 1 ? { color: "red" } : {}}
                                // onMouseEnter={(event) => onMouseOver(event)}
                                // onMouseOut={(event) => onMouseOut(event)}
                              >
                                <img
                                  className={styles.allCategoriesImg}
                                  src={level1.path}
                                  alt={level1.name}
                                  onClick={() => {
                                    // router.push({
                                    // pathname:
                                    window.location =
                                      "/spares/" +
                                      item.slug +
                                      "/" +
                                      level1.nameId;
                                    // });
                                    // console.log("/spares/"+item.slug +"/"+ level1.nameId);
                                  }}
                                />{" "}
                                <div
                                  className={styles.allCategoryName}
                                  onClick={() => {
                                    // router.push({
                                    // pathname:
                                    window.location =
                                      "/spares/" +
                                      item.slug +
                                      "/" +
                                      level1.nameId;
                                    // });
                                    // console.log("/spares/"+item.slug +"/"+ level1.nameId);
                                  }}
                                >
                                  {level1.name}
                                </div>
                                <div className={`sub-sub-menu`}>
                                 {level1.children.length && <div className="sub-megamenu">
                                    {level1.children.length > 0 &&
                                      level1.children
                                        .slice(0, 10)
                                        .map((level2) => {
                                          return (
                                            <div
                                              // onClick={() => toggleCategoriesModal(item.id)}

                                              className="sub-item"
                                              // style={clicked === 1 ? { color: "red" } : {}}
                                              // onMouseEnter={(event) => onMouseOver(event)}
                                              // onMouseOut={(event) => onMouseOut(event)}
                                            >
                                              <img
                                                className={
                                                  styles.allCategoriesImg
                                                }
                                                src={level2.path}
                                                alt={level2.name}
                                                onClick={() => {
                                                  // router.push({
                                                  // pathname:
                                                  window.location =
                                                    "/spares/" +
                                                    item.slug +
                                                    "/" +
                                                    level1.nameId +
                                                    "/" +
                                                    level2.nameId;

                                                  // });
                                                }}
                                              />{" "}
                                              <div
                                                className={
                                                  styles.allCategoryName
                                                }
                                                onClick={() => {
                                                  // router.push({
                                                  // pathname:
                                                  window.location =
                                                    "/spares/" +
                                                    item.slug +
                                                    "/" +
                                                    level1.nameId +
                                                    "/" +
                                                    level2.nameId;

                                                  // });
                                                }}
                                              >
                                                {level2.name}
                                              </div>
                                            </div>
                                          );
                                        })}
                                        {level1?.children?.length < 10 ? (
                          <></>
                        ) : (
                          <div
                            className={`${styles.categoriesDiv} categoriesDivs`}
                            onClick={() => router.push("/spares/" + item.slug+"/"+level1.nameId)}
                          >
                            {"See all >"}
                          </div>
                        )}
                                  </div>}
                                </div>
                              </div>
                            );
                          })}
                        {item?.children?.length < 10 ? (
                          <></>
                        ) : (
                          <div
                            className={`${styles.categoriesDiv} categoriesDivs`}
                            onClick={() => router.push("/spares/" + item.slug)}
                          >
                            {"See all >"}
                          </div>
                        )}
                      </div>}
                    </div>
                  </li>
                );
              })}
            {categoryTree?.length < 11 ? (
              <></>
            ) : (
              <div
                className={`${styles.categoriesDiv} categoriesDivs colorPrimaryRed`}
                onClick={() => router.push("/spares")}
              >
                {"More +"}
              </div>
            )}
          </ul>
        )}

        <div>
          <p className="follow-us">
            Follow us{" "}
            <a
              href="https://www.instagram.com/spare_4_india_com?utm_medium=copy_link"
              rel="noreferrer"
              target="_blank"
              className=""
            >
              <FaInstagram size={18} className="footer-social-icon" />
            </a>
            <a
              href="https://www.facebook.com/people/Spares4indiacom/100094609835293/"
              rel="noreferrer"
              target="_blank"
              className="mx-2"
            >
              <FaFacebook size={18} className="footer-social-icon" />
            </a>
          </p>
        </div>

        {/* <TabPanel>
            {renderSubCategoriesList()}

            <div
              className={styles.categoriesDiv}
              // style={clicked === 1 ? { color: "red" } : {}}
              onClick={() => router.push("/spares")}
            >
              {"More +"}
            </div>
          </TabPanel> */}

        {renderCateogriesModal()}
      </div>
    </>
  );
};

export default Categories;
