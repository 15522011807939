import React from "react";

import Loader from "../Assets/Loaders/loader.gif";

export default class SplashScreen extends React.Component {

    render() {

        return(
            <div
                className="d-flex flex-row align-items-center justify-content-center"
                style={{
                    position: "fixed",
                    height: "100vh",
                    width: "100vw",
                    zIndex: "5000",
                    backgroundColor: "#f0f0f0",
                    top: "0",
                    left: "0",
                    overflow:"hidden"
                }}
            >
                
                <img 
                    src={ Loader }
                    style={{
                        width: "100px"
                    }}
                />

            </div>
        )

    }

}