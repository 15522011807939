import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logoutmodal from "../../Components/LogoutModal";
import { useEffect } from "react";

import "../../Assets/Styling/Profile/ProfileSidebar.css";
import {CgProfile} from "react-icons/cg"
import {BsInboxFill} from "react-icons/bs"
import {AiFillHome} from "react-icons/ai"
import {RiLockPasswordFill} from "react-icons/ri"
import {HiOutlineLogout} from "react-icons/hi"
import {FaListUl} from "react-icons/fa"
var Globals = require("../../utils/Globals");

var ProfileSidebar = (props) => {
    const location = useLocation();
    const [isModal, setModal] = useState(false);

    function closeModal() {
        setModal(false);
    }

    function logout() {
        setModal(false);
        localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA);
        localStorage.removeItem(
            Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES
        );
        localStorage.removeItem("sparesToken");
        props.props.history.push("/");
    }

    const [device, setDevice] = useState("Home");

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            setDevice("mobile");
        } else {
            setDevice("Home");
        }

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
        );
        if (isTablet) {
            setDevice("tablet");
        }
    }, [device]);

    return (
        <div className="col-2 sidebar-section d-flex flex-column align-items-start justify-content-start py-0 px-0">
            <Link
                to="/account/profile"
                className={
                    "sidebar-link " +
                    (location.pathname === "/account/profile"
                        ? "sidebar-link-active"
                        : "")
                }
            >
                <div className="sidebar-button color-white">
                    {
                        device!=="mobile"? 
                        <span className="sidebar-button-text d-flex flex-row align-items-center">
                            <CgProfile size={18}/> &nbsp; <span>Profile</span>
                        </span>:
                        
                        <CgProfile />
                    }
                </div>
            </Link>

            <Link
                to="/account/my-orders"
                className={
                    "sidebar-link " +
                    ((location.pathname === "/account/my-orders" || location.pathname === "/account/my-orders/order-details/view")
                        ? "sidebar-link-active"
                        : "")
                }
            >
                {/* <div className="sidebar-button">{device!=="mobile"?"My Orders":<BsInboxFill />}</div> */}
            
                <div className="sidebar-button color-white">
                    {
                        device!=="mobile"? 
                        <span className="sidebar-button-text d-flex flex-row align-items-center">
                            <BsInboxFill size={16}/> &nbsp; <span>My orders</span>
                        </span>:
                        
                        <BsInboxFill />
                    }
                </div>
            </Link>

            <Link
                to="/account/addresses"
                className={
                    "sidebar-link " +
                    (location.pathname === "/account/addresses"
                        ? "sidebar-link-active"
                        : "")
                }
            >
                {/* <div className="sidebar-button">{device!=="mobile"?"Addresses":<AiFillHome />}</div> */}
            
                <div className="sidebar-button color-white">
                    {
                        device!=="mobile"? 
                        <span className="sidebar-button-text d-flex flex-row align-items-center">
                            <AiFillHome size={16}/> &nbsp; <span>Addresses</span>
                        </span>:
                        
                        <AiFillHome />
                    }
                </div>

            </Link>

            <Link
                to="/account/manage-password"
                className={
                    "sidebar-link " +
                    (location.pathname === "/account/manage-password"
                        ? "sidebar-link-active"
                        : "")
                }
            >
                {/* <div className="sidebar-button">{device!=="mobile"?"Manage Password":<RiLockPasswordFill />}</div> */}
            
                <div className="sidebar-button color-white">
                    {
                        device!=="mobile"? 
                        <span className="sidebar-button-text d-flex flex-row align-items-center">
                            <RiLockPasswordFill size={16}/> &nbsp; <span>Manage Password</span>
                        </span>:
                        
                        <RiLockPasswordFill />
                    }
                </div>

            </Link>

            <Link
                to="/account/wishlist"
                className={
                    "sidebar-link " +
                    (location.pathname === "/account/wishlist"
                        ? "sidebar-link-active"
                        : "")
                }
            >
                {/* <div className="sidebar-button">{device!=="mobile"?"Manage Password":<RiLockPasswordFill />}</div> */}
            
                <div className="sidebar-button color-white">
                    {
                        device!=="mobile"? 
                        <span className="sidebar-button-text d-flex flex-row align-items-center">
                            <FaListUl size={16}/> &nbsp; <span>Wishlist</span>
                        </span>:
                        
                        <FaListUl />
                    }
                </div>

            </Link>
                <div className="sidebar-button sidebar-link color-white" onClick={() => setModal(true)}>
                    {/* <span  className={"sidebar-link "}> */}
                {/* <div className="sidebar-button">{device!=="mobile"?"Logout":<HiOutlineLogout/>}</div> */}
            
                    {
                        device!=="mobile"? 
                        <span className="sidebar-button-text d-flex flex-row align-items-center">
                            <HiOutlineLogout size={16}/> &nbsp; <span>Logout</span>
                        </span>:
                        
                        <HiOutlineLogout />
                    }
                {/* </span> */}
                </div>

            <Logoutmodal
                isModal={isModal}
                closeModal={() => closeModal()}
                logout={() => logout()}
            />
        </div>
    );
};

export default ProfileSidebar;
