import React from "react";

import Loader from "../Assets/Loaders/loader.gif";

export default class ComponentLoader extends React.Component {

    render() {

        return(
            <div
                className="d-flex flex-row align-items-center justify-content-center"
                style={{
                    height: "100%",
                    width: "100%",
                    overflow:"hidden"
                }}
            >
                
                <img 
                    src={ Loader }
                    style={{
                        width: "100px"
                    }}
                />

            </div>
        )

    }

}