var Globals = require("./Globals");

var baseUrl = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;

module.exports = {
  URLS: {
    /* Auth */
    adminLogin: baseUrl + "Users/adminLogin/",
    customerLogin: baseUrl + "Users/customerLogin/",
    customerSignup: baseUrl + "Users/customerSignup/",
    googleLogin: baseUrl + "Users/googleLogin/",
    facebookLogin: baseUrl + "Users/facebookLogin/",
    googleAuth: baseUrl + "api/v1/auth/google",
    /** End of Auth */

    /* User/Profile */
    getUser: baseUrl + "Users/getUser/",
    updateProfile: baseUrl + "Users/updateProfile/",
    listAllUsers: baseUrl + "Users/listAllUsers",
    addAddress: baseUrl + "Address/addAddress",
    getUserAddresses: baseUrl + "Address/getUserAddresses",

    changePassword: baseUrl + "Users/ChangePassword",
    updatePhoneNumber: baseUrl + "Users/updatePhoneNumber",
    forgotPassword: baseUrl + "Users/forgotPassword",
    /** End of Auth */

    /** Coupon */
    addCoupon: baseUrl + "CouponCodes/addCoupon",
    editCoupon: baseUrl + "CouponCodes/editCouponCode",
    deleteCouponCode: baseUrl + "CouponCodes/deleteCouponCode",
    getAllCoupons: baseUrl + "CouponCodes/getAllCoupons",
    getCouponCode: baseUrl + "CouponCodes/getCouponCode",
    /** End of Coupon */

    /** Products */
    addProduct: baseUrl + "Products/createProduct",
    editProduct: baseUrl + "Products/editProduct",
    getProductById: baseUrl + "getProduct",
    getProductByIdForAdmin: baseUrl + "Products/getProductByIdForAdmin",
    deleteproduct: baseUrl + "Products/deleteProduct",
    getAllProducts: baseUrl + "Products/getAllProducts",
    getProducts: baseUrl + "Products/getProductsByCategory",
    getLowStockProducts: baseUrl + "Products/getLowStockProducts",
    bulkUpload:baseUrl + "Products/bulkUpload",
    excelStockUpdate: baseUrl + "Products/excelStockUpdate",
    updateDefaultImage: baseUrl + "Products/updateDefaultImage",
    /** End of Products */

    /** Product Images */
    deleteProductImage: baseUrl + "products/deleteProductImage",
    /** End of Product Images */

    /** Categories */
    addCategory: baseUrl + "Category/addCategory",
    editCategory: baseUrl + "Category/editCategory",
    getAllCategories: baseUrl + "Category/getAllCategories",
    getAllSubCategories: baseUrl + "Category/getAllSubCategories",
    getCategoryTree: baseUrl + "Category/getCategoryTree",
    deleteCategory: baseUrl + "Category/deleteCategory",
    getCategorySearchPage: baseUrl + "Category/getCategorySearchPage", // ? CATEGORY DETAILS FOR SEARCH PAGE BREAD-CRUMBS
    /** End of Categories */

    /** Tags */
    getAllTags: baseUrl + "Tags/getAllTags",
    addTag: baseUrl + "Tags/createTag",
    editTag: baseUrl + "Tags/editTag",
    deleteTag: baseUrl + "Tags/deleteTag",
    /** End of Tags */

    /** Cart */
    cartList: baseUrl + "Cart/cartList",
    addToCart: baseUrl + "Cart/addToCart",
    removeFromCart: baseUrl + "Cart/removeFromCart",
    /** End of Cart */

    /** Blog */
    addBlog: baseUrl + "Blogs/createBlogPost",
    editBlog: baseUrl + "Blogs/editBlogPost",
    getAllBlogPosts: baseUrl + "Blogs/getAllBlogPosts",
    deleteBlog: baseUrl + "Blogs/deleteBlogPost",
    getSingleBlog: baseUrl + "Blogs/getBlogPost",
    deleteBlogImage: baseUrl + "Blogs/deleteBlogPostImage",
    /** End of Blog */

    allCoupons: baseUrl + "CouponCodes/getAllCoupons",
    allCouponsCode: baseUrl + "CouponCodes/applyCouponCode",
    removeCouponCode: baseUrl + "CouponCodes/removeCouponCode",

    /** Checkout */
    checkoutInt: baseUrl + "Checkout/checkoutInit",
    /** End of Checkout */

    /** Order/Payment */
    paymentInit: baseUrl + "Order/paymentInit",
    paymentComplete: baseUrl + "Order/paymentComplete",
    /** End of Order/Payment */

    /** Banner Images */
    addOrEditBanner: baseUrl + "BannerImages/AddOrEditBannerImage",
    getAllBannerImage: baseUrl + "BannerImages/GetAllBannerImage",
    deleteBannerImage: baseUrl + "BannerImages/DeleteBannerImage",
    /** End of Banner Images */

    /** Addresses */
    getAllUserAddresses: baseUrl + "Address/getUserAddresses",
    deleteAddress: baseUrl + "Address/deleteAddress",
    editAddress: baseUrl + "Address/editAddress",
    /** End of Addresses */

    /** Orders */
    getOrder: baseUrl + "Order/getSingleOrder",
    getUserOrders: baseUrl + "Order/getUserOrders",
    getOrderDetails: baseUrl + "Order/getOrderDetails",
    getAllOrders: baseUrl + "Order/getAllOrders",
    /** End of Orders*/

    /** Contact page Queries */
    createQuery: baseUrl + "Contact/createQuery",
    getAllQueries: baseUrl + "Contact/getAllQueries",
    /** End of Contact page Queries */

    /** Newsletter */
    createSubscriber: baseUrl + "Newsletter/createSubscriber",
    /** End of Newsletter */

    /** CSV Export */
    /* PRODUCTS */

    exportProducts: baseUrl + "Products/exportCsv",
    exportLowStockCsv: baseUrl + "Products/exportLowStockCsv",

    /* USERS */

    exportUsers: baseUrl + "Users/exportCsv",

    /* ORDERS */
    exportAllOrders: baseUrl + "Order/exportAllOrders",
    exportDateOrders: baseUrl + "Order/exportDateOrders",
    generatePaymentLink: baseUrl + "paymentLink",
    paymentLinkCallback: baseUrl + "payment/verify",
    /** End of CSV Export */

    /* CATEGORIES */

    searchCategory: baseUrl + "Products/getAllProductsSearchPage",
    allCompanies: baseUrl + "Products/getAllCompanies",
    bannerImages: baseUrl + "BannerImages/GetAllBannerImage",
    getCategoryTreeByParentCategory:
      baseUrl + "Category/getCategoryTreeByParentCategory",
    getDistinctSubCategories: baseUrl + "Category/getDistinctSubCategories",
    distinctProductsList: baseUrl + "Products/shopByCategories",
    /** END OF CATEGORIES */

    // wishlist

    addToWishlist: baseUrl + "wishlist/add",
    fetchWishlist: baseUrl + "wishlist/all",
    deleteItemFromWishlist: baseUrl + "wishlist/remove",
    // pincodeShippingCharges: baseUrl + "Shipment/pincodeShippingCharges",
    // checkPinCode: baseUrl + "Shipment/checkPincode",
    // checkPinCodeProductPage: baseUrl + "Shipment/checkPinCodeProduct",

    dashboardNumbers: baseUrl + "AdminHelper/dashboardNumbers",

    //rating
    addorEditRating: baseUrl + "setRating",
    getRatingForProduct: baseUrl + "allRating/product",
    
    //order Status
    orderStatus : baseUrl+"order/updateOrder",
    //career form
    careerForm: baseUrl + "career-form",
    BASE_URL: baseUrl,
  },
};
