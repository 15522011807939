import React from "react";
import MainSection from "./MainSection";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import bigimage from "../../Assets/Images/img/pump.png";
import styles from "../../Assets/Styling/search.module.css";
import { toast } from "react-toastify";
import API_Services from "../../utils/API_Services";
import ComponentLoader from "Components/ComponentLoader";
import Commitments from "Components/Commitments";

var UrlConstants = require("../../utils/UrlConstants");

var _ = require("lodash");

let data = [];

class AllSubCategories extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			category_tree: {},
			loader: true,
		};
	}

	getAllCategories = async () => {

		API_Services.httpPOST(
			UrlConstants.URLS.getDistinctSubCategories,
			{},
			async (err, response) => {
				if (err) {
					if (err.response && err.response.data) {
						toast.error(err.response.data.message);
					} else {
						toast.error("Something went wrong!");
						console.log("err...", err);
					}
				}
				if (response.data) {
					if (response.data.status) {
						this.setState({
							category_tree: response.data.data,
							loader: false,
						})
					} else {
						toast.error(response.data.message);
						window.location = "/";
					}
				} else {
					toast.error("Something went wrong!");
				}
			}
		);
	};

	componentDidMount = () => {
		// const { categorySlug } = this.props.match.params;

		// if (categorySlug === "" || categorySlug === "/category") {
		// 	window.location.href = "/";
		// }

		this.getAllCategories();
	};

	rightSide = () => {

		if(this.state.loader) {

			return <ComponentLoader />;

		} else {
			
			return (
				<>

					<MainSection data={this.state.category_tree} description={this.state.category_tree['description']} />
				
				</>
			);
		}


	};

	render() {
		return (
			<div>
				<Navbar />
				<br />
				<div>
					<Container fluid="md content-section">
						<Row className={styles.wrapper}>
							{/* <Col className={styles.leftWrapper}></Col> */}
							<Col>{this.rightSide()}</Col>
						</Row>
					</Container>
				</div>

				<br />
				<div className="px-4 content-section">
					<Commitments />
				</div>
				<Footer />
			</div>
		)
	};
}

export default AllSubCategories;