import React, { memo } from "react";
import { Button, Modal } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import {ImSwitch} from 'react-icons/im';
import "../Assets/Styling/logoutmodal.css";
import on from "../Assets/Images/on.png";
const Logoutmodal = memo((props) => {
    return (
        <Modal isOpen={props.isModal} >
            <div
                className=""
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >

              
                <div
                    className="categories-close-section"
                    style={{
                        right: 10,
                        top:10,
                        position: "absolute",
                        zIndex:1
                    }}
                    onClick={() => props.closeModal()}
                >
                    <FaTimes
                        className="categories-close-btn"
                    />
                </div>
            </div>
                    <div className="modal-body justify-content-center text-center p-4 rounded-0">
                   <div className="p-4" >
                   <img src={on} style={{width:"50px",height:"50px"}}  />
                    </div> 
                    <p className="modal-title text-center">
                    {"Are you sure you want to logout?"}
                </p>
                    </div>
            <div
                className="modal-footer justify-content-center border-top-0 pb-5" 
            >
                <Button
                    onClick={() => props.closeModal()}
                    className="rounded-0 logout-modal-close"
                >
                    Close
                </Button>
                <Button
                className="rounded-0 logout-modal-act"
                    onClick={() => props.logout()}
                >
                    Logout
                </Button>
            </div>
        </Modal>
    );
});

export default Logoutmodal;
