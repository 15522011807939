import axios from "axios";
import { API_CONSTANTS } from "../Contants/APIConstants";

var UrlConstants = require("../utils/UrlConstants");

export const productService = {
    getProducts,
    getAllCategory,
    deleteproduct,
    getAllSubCategories,
    addProduct,
    getProductById,
    editProduct,
};

async function getProducts(data) {
    return axios.post(
        UrlConstants.URLS.BASE_URL + "Products/getProductsByCategory",
        data
    );
}

async function getAllCategory() {
    return axios.post(UrlConstants.URLS.BASE_URL + "Category/getAllCategories");
}

async function deleteproduct(data) {
    return axios.post(UrlConstants.URLS.BASE_URL + "Products/deleteProduct", data);
}

async function getAllSubCategories(data) {
    return axios.post(
        UrlConstants.URLS.BASE_URL + "Category/getAllSubCategories",
        data
    );
}

async function addProduct(data, product_images) {
    const headers = {
        "Content-Type": "multipart/form-data",
    };

    const dataToProcess = new FormData();

    for (var key in data) {
        if (data[key].constructor === Array) {
            for (var x = 0; x < data[key].length; x++) {
                dataToProcess.append(key, data[key][x]);
            }
        } else {
            dataToProcess.set(key, data[key]);
        }
    }

    for (var x = 0; x < product_images.length; x++) {
        dataToProcess.append("images", product_images[x]);
    }

    return axios.post(
        UrlConstants.URLS.BASE_URL + "Products/createProduct",
        dataToProcess,
        headers
    );
}

async function editProduct(data) {
    return axios.post(UrlConstants.URLS.BASE_URL + "Products/editProduct", data);
}

async function getProductById(data) {
    return axios.post(UrlConstants.URLS.BASE_URL + "getProduct", data);
}
