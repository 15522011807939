import axios from "axios";
import { toast } from "react-toastify";

import Util from './Util';

export default class API_Services {

	static httpGET(endPoint, callback) {

		axios
			.get(endPoint)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				if (err.response && err.response.status && err.response.status == 402) {

					callback(err, null);
				};

				callback(err, null);
			});
	}

	static httpGETWithToken(endPoint, callback) {

		var coreData = Util.getUser();

		var apiURL = Util.generateBaseAPIURLWithUserToken(coreData?.userCoreProperties, endPoint);

		axios
			.get(apiURL)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				// Forbidden. Not allowed to access the page
				if (err.response && err.response.status && err.response.status == 403) {

					let navURL = '/';

					if (err.response.data && err.response.data.redirect_url) {
						navURL = err.response.data.redirect_url;
					};
					toast.error(err.response.data.message);
					return window.location = navURL;
				};

				callback(err, null);
			});
	}

	static httpPOST(endPoint, postObj, callback) {

		axios
			.post(endPoint, postObj)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				if (err.response && err.response.status && err.response.status == 402) {

					callback(err, null);
				};

				callback(err, null);
			});
	}

	static httpPOSTWithToken(endPoint, postObj, callback) {

		var coreData = Util.getUser();

		var apiURL = Util.generateBaseAPIURLWithUserToken(coreData?.userCoreProperties, endPoint);


		axios
			.post(apiURL, postObj)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				// Forbidden. Not allowed to access the page
				if (err.response && err.response.status && err.response.status == 403) {

					let navURL = '/';

					if (err.response.data && err.response.data.redirect_url) {
						navURL = err.response.data.redirect_url;
					};
					toast.error(err.response.data.message);
					return window.location = navURL;
				};

				callback(err, null);
			});
	}
}